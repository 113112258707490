import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import ParametroSelect from "components/CustomInput/ParametroSelect";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';

import * as documentoActions from "actions/DocumentoActions";
import * as loadActions from "actions/LoadActions";
import * as usuarioActions from "actions/UsuarioActions";
import * as notificationActions from "actions/NotificationActions";
import { s2ab } from 'utils/Functions';
import { getFormatDate, getRestarDias } from 'utils/Functions';
import { change } from 'redux-form'
import { DATA_DISTRITOS, DATA_ESTADOS, DATA_TIPO_DOCUMENTO,  DATA_AREA_INDURAMA, DATA_AREA_ILUMI, DATA_AREA_BARKER} from 'utils/GeneralData';
const required = value => value ? undefined : 'Valor Requerido'

const validate = values => {
  const errors = {}
  var fechaActual = getFormatDate(new Date(),  'ymd', '-')

  if (!values.tipReporte) {
    errors.tipReporte = 'Seleccione el tipo de reporte'
  }

  if (!values.typeGraph) {
    errors.typeGraph = 'Seleccione el tipo de grafico'
  }

  

  if (!values.hFechaInicio) {
    errors.hFechaInicio = 'Valor Requerido'
  } else if (values.hFechaInicio > values.hFechaFin) {
    errors.hFechaInicio = ' '
  } 

  if (!values.hFechaFin) {
    errors.hFechaFin = 'Valor Requerido'
  } else if (values.hFechaFin < values.hFechaInicio) {    
    errors.hFechaFin = ' '
  }else if (values.hFechaFin > fechaActual) {    
    errors.hFechaFin = 'La fecha fin no puede ser mayor a la fecha actual'
  }else if (values.hFechaInicio > fechaActual) {    
    errors.hFechaFin = 'La fecha inicio no puede ser mayor a la fecha actual'
  }

  if (values.typeGraph && values.typeGraph == 'PIE' && !values.compania) {
    errors.compania = 'Valor Requerido para este tipo de consulta'
  }
  
  if (values.hFechaInicio && values.hFechaFin) {
    var fechaInicio = new Date(values.hFechaInicio).getTime();
    var fechaFin    = new Date(values.hFechaFin).getTime();
    var diff = fechaFin - fechaInicio;
    var difDias = diff/(1000*60*60*24);
    //if (difDias > 30 ){
      // errors.hFechaFin = 'La diferencia de fechas no puede ser mayor a 30 dias'
    //}
  }

  return errors
}

/****end new */

class RepDocumentoForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      tipDocuments: [],
      statesSunat: [],
      lstCdr: [],
      lstTypeDocument: [],
      graficos: [],
      tipReporte: [],
      companias: [],
      documento: {
        docuNumero: '',
        tipoDocumento: [],
        clieNumero: '',  //es ruc
        docuFechaIni: '',
        docuFechaFin: '',
        estado: [],
        clieNombre: '', //razon Social
        cdr: '',
        documentTypeClient: '',
        compania: '',
        producto: '',
        estado: '',
        marca: '',
        tipoServicio: '',
        area: '',
        modelo: '',
        serie: '',
        clieTipDoc: '',
        numeroservicio: '',
        numeroFicha: '',
        telefono: ''
      },
      estadoArtefactos: [],
      artefactos:[],
      marcas: [],
      tipoServicios: [],
      areas: [],
      fecdefault: getFormatDate(new Date(),  'ymd', '-'),
      errorFecha: '',
      errorFechaFin: '',
    }
  }
  componentDidMount() {
    //this.buscarParametroFiltro();
    this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('hFechaFin', getFormatDate(new Date(), 'ymd', '-'))
    this.state.documento.docuFechaIni = getFormatDate(new Date(), 'ymd', '-')
    this.state.documento.docuFechaFin = getFormatDate(new Date(), 'ymd', '-')
    //para filtros exportar excel
    this.props.documento.docuFechaIni = getFormatDate(new Date(), 'ymd', '-')
    this.props.documento.docuFechaFin = getFormatDate(new Date(), 'ymd', '-')

    this.state.tipDocuments = DATA_TIPO_DOCUMENTO;
    this.state.lineas = JSON.parse(localStorage.getItem("lineas_serfac")); // DATA_LINEAS;
    this.state.recepcionistas = JSON.parse(localStorage.getItem("recepcionistas_serfac")); // DATA_RECEPCIONISTA;
    this.state.areas = JSON.parse(localStorage.getItem("areas_serfac"));  // DATA_AREA;
    this.state.artefactos = JSON.parse(localStorage.getItem("productos_serfac")); //DATA_ARTEFACTO;
    this.state.marcas = JSON.parse(localStorage.getItem("marcas_serfac")); //DATA_MARCA;
    this.state.tipoServicios = JSON.parse(localStorage.getItem("tiposervicios_serfac")); // DATA_TIPO_SERVICIO; 
    this.state.companias = JSON.parse(localStorage.getItem("ubigeos_serfac")); //DATA_DISTRITOS;
    this.state.estadoArtefactos = DATA_ESTADOS


    if (localStorage.getItem("tkn") == "serfac-ilumi"){
      this.state.areas = DATA_AREA_ILUMI; // solo para ILUMI login
      this.state.documento.marca = 15;
    }
    if (localStorage.getItem("tkn") == "serfac-barker"){
      this.state.areas = DATA_AREA_BARKER; // solo para ILUMI login
      this.state.documento.marca = 20;
    }

    if (localStorage.getItem("tkn") == "serfac-indurama"){
      this.state.areas = DATA_AREA_INDURAMA; // solo para ILUMI login
      this.state.documento.marca = 18;
    }


  }
  buscarParametroFiltro() {
    const { show } = this.props.notificationActions;
    this.props.actions.listarParametrosFiltroDocumento('listparamconsult').then(response => {
      if (response.tipo !== TIPO_MENSAJE.SUCCESS) {
        show(response.indMostrarMensaje, response.mensaje, response.tipo);
      } else {
        if (response.data.length === 0) {
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "No se encontraron documentos a mostrar.", TIPO_MENSAJE.WARNING);
        } else {
          var lstTipDoc = [];
          var lstCdr = [];
          var lstSendSunar = [];
          var lstTypeDocument = [];
          var graficos = [];
          var tipReporte = [];
          var companias = [];
         // lstTipDoc.push({id:' ', valor: 'TODOS'});
          lstCdr.push({id:' ', valor: 'TODOS'});
         // lstSendSunar.push({id:' ', valor: 'TODOS'});
          for (var i = 0; i < response.data.length; i++) {
            var item = {};
            if (response.data[i].cod_grup_parm == '1') {
              item = {
                id: "'" + response.data[i].val_parm_nume + "'",
                valor: response.data[i].nom_parm
              };
              lstTipDoc.push(item);
            }
            if (response.data[i].cod_grup_parm == '2') {
              item = {
                id: response.data[i].val_parm_nume,
                valor: response.data[i].nom_parm
              };
              lstSendSunar.push(item);
            }
            if (response.data[i].cod_grup_parm == '5') {
              item = {
                id: response.data[i].val_parm_nume,
                valor: response.data[i].nom_parm
              };
              lstTypeDocument.push(item);
            }
            if (response.data[i].cod_grup_parm == '7') {
              item = {
                id: response.data[i].val_parm_nume,
                valor: response.data[i].nom_parm
              };
              companias.push(item);
            }

            
          }          
          graficos.push({ id: 'PIE', valor: 'CIRCULAR' });
          graficos.push({ id: 'BAR', valor: 'BARRA' });
          graficos.push({ id: 'LINEA', valor: 'LINEAS' });

          tipReporte.push({ id: 'C', valor: 'Cantidad de Documentos' });
          tipReporte.push({ id: 'M', valor: 'Monto de Transacciones' });
          tipReporte.push({ id: 'E', valor: 'Estados SUNAT' });

          this.setState({ tipDocuments: lstTipDoc })
          this.setState({ statesSunat: lstSendSunar })
          this.setState({ lstCdr: lstCdr })
          this.setState({ lstTypeDocument: lstTypeDocument })
          this.setState({ graficos: graficos })
          this.setState({ tipReporte: tipReporte })
          this.setState({ companias: companias })
        }
      }
    })
  }

  getSelectValue = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    this.setState({ documento: documento });
  }
  limpiarForm = () => {
    this.props.reset();
    var documento = {
      docuNumero: '',
      tipoDocumento: [],
      clieNumero: '',  //es ruc
      docuFechaIni: getFormatDate(new Date(), 'ymd', '-'),
      docuFechaFin: getFormatDate(new Date(), 'ymd', '-'),
      estado: '',
      clieNombre: '', //razon Social
      cdr: '',
      documentTypeClient: '',
      typeGraph: '',
      producto: '',
      tipoServicio: '',
      marca: '',
      area: '',
      modelo: '',
      serie: '',
      clieTipDoc: '',
      numeroservicio: '',
      numeroFicha: '',
      telefono: ''
    };
    this.setState({ documento: documento });
    this.props.change('hFechaInicio', getFormatDate(new Date(), 'ymd', '-'))
    this.props.change('hFechaFin', getFormatDate(new Date(), 'ymd', '-'))
    this.setState({ errorFecha: false });
    this.setState({ errorFechaFin: false });

  }

  setFechaIni = (event) => {
    let fecha = event.target.value;
    //console.log("la fecha de fin es: " + this.state.documento.docuFechaFin);
    if ((this.state.documento.docuFechaFin && fecha) && (fecha > this.state.documento.docuFechaFin)) {
      this.state.documento.docuFechaIni = fecha;
      this.props.change('hFechaInicio', fecha)
      this.setState({ errorFecha: "Es mayor a la fecha fin." });
    } else {
      this.setState({ errorFechaFin: false });
      this.setState({ errorFecha: false });
      this.state.documento.docuFechaIni = fecha;
      this.props.change('hFechaInicio', fecha)
    }
  }

  setFechaFin = (event) => {
    let fecha = event.target.value;
    if ((this.state.documento.docuFechaIni && fecha) && (fecha < this.state.documento.docuFechaIni)) {
      this.state.documento.docuFechaFin = fecha;
      this.props.change('hFechaFin', fecha)
      this.setState({ errorFechaFin: "Es menor a la fecha inicio." });
    } else {
      this.setState({ errorFecha: false });
      this.setState({ errorFechaFin: false });
      this.state.documento.docuFechaFin = fecha;
      this.props.change('hFechaFin', fecha)
    }
  }

  render() {
    const { handleSubmit, classes } = this.props;
    const { documento, fecdefault, errorFecha, errorFechaFin } = this.state;
    return (
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Número de Ficha"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "numeroFicha",
                      disabled: this.state.disabledFicha
                    }}
                    normalize={(val, prevVal) => {
                      if (val.length > 10)
                        return prevVal;
                      
                      if (val) {
                        return (!/[^0-9--_/]/.test(val)) ? val : prevVal
                      }
                      return val;
                    }}
                    component={CustomInput}
                    value={documento.numeroFicha}
                    name="numeroFicha"
                    id="numeroFicha" /> 
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Estado"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "estado",
                    name: "estado"
                  }}
                  multiple={true}
                  data={this.state.estadoArtefactos}
                  component={CustomAutocomplete}
                  valor={documento.estado}
                  //validate={[ required ]}
                  getValue={this.getSelectValue}
                  val="id"
                  txt="valor"
                  name="estado"
                  id="estado" />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Productos"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "producto",
                    name: "producto"
                  }}
                  multiple={true}
                  data={this.state.artefactos}
                  component={CustomAutocomplete}
                  valor={documento.producto}
                  getValue={this.getSelectValue}
                  val="id"
                  txt="valor"
                  name="producto"
                  id="producto" />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <Field labelText="Area Asignada"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "area",
                    name: "area"
                  }}
                  multiple={true}
                  data={this.state.areas}
                  component={CustomAutocomplete}
                  valor={documento.area}
                  getValue={this.getSelectValue} 
                  val="id"
                  txt="valor"
                  name="area"
                  id="area" />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Marca"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "marca",
                    name: "marca"
                  }}
                  multiple={true}
                  data={this.state.marcas}
                  component={CustomAutocomplete}
                  valor={documento.marca}
                  getValue={this.getSelectValue} 
                  val="id"
                  txt="valor"
                  name="marca"
                  id="marca" />
               </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Tipo de Servicio"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "tipoServicio",
                    name: "tipoServicio"
                  }}
                  multiple={false}
                  data={this.state.tipoServicios}
                  component={CustomAutocomplete}
                  valor={documento.tipoServicio}
                  getValue={this.getSelectValue}
                  val="id"
                  txt="valor"
                  name="tipoServicio"
                  id="tipoServicio" />
              </GridItem>
            </GridContainer>
          </GridItem>


          <GridItem xs={12} sm={12} md={12}>
            <GridContainer> 
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Modelo"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "modelo",
                    min: "1"
                  }} 
                  normalize={(val, prevVal) => {
                    if (val.length > 20)
                      return prevVal;
                    if (val) {
                      return (!/[^a-zA-Z0-9--_/]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={documento.modelo}
                  name="modelo"
                  id="modelo" />
               </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Serie"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "serie",
                    min: "1"
                  }} 
                  normalize={(val, prevVal) => {
                    if (val.length > 20)
                      return prevVal;
                    if (val) {
                      return (!/[^a-zA-Z0-9--_/]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={documento.serie}
                  name="serie"
                  id="serie" />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Número de O/S"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "numeroservicio" 
                  }}                    
                  component={CustomInput}
                  value={documento.numeroservicio}
                  name="numeroservicio"
                  id="numeroservicio" />
              </GridItem>
            </GridContainer>
          </GridItem>


          <GridItem xs={12} sm={12} md={12}>
            <GridContainer> 
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Tipo Documento Cliente"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "clieTipDoc",
                    name: "clieTipDoc"
                  }}
                  multiple={false}
                  data={this.state.tipDocuments}
                  component={CustomAutocomplete}
                  valor={documento.clieTipDoc}
                  getValue={this.getSelectValue}
                  placeholder="Placeholder"
                  label="With placeholder"
                  val="id"
                  txt="valor"
                  name="clieTipDoc"
                  id="clieTipDoc" />
               </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Número Documento"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "clieNumero",
                    name: "clieNumero",
                    min: "1"
                  }}
                  component={CustomInput} 
                  value={documento.clieNumero}
                  name="clieNumero"
                  id="clieNumero" />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Teléfono"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "telefono",
                    name: "telefono",
                    min: "1"
                  }}
                  component={CustomInput} 
                  value={documento.telefono}
                  name="telefono"
                  id="telefono" />
              </GridItem>
            </GridContainer>
          </GridItem>



          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput labelText="Fecha Inicio"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "docuFechaIni",
                    name: "docuFechaIni",
                    type: "date",
                    //defaultValue: fecdefault,
                    onChange: this.setFechaIni,
                    value:  documento.docuFechaIni,
                    format: 'DD-MM-YYYY'
                  }}
                 // validate={[ required ]}
                  meta={{ touched: true, error: errorFecha, warning: false }}
                  //value={documento.docuFechaIni}
                  //name="docuFechaIni"
                 // id="docuFechaIni"
                   />
                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hFechaInicio",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.docuFechaIni}
                  name="hFechaInicio"
                  id="hFechaInicio" />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput labelText="Fecha Fin"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "date",
                    name: "txtfechafin",
                    //defaultValue: fecdefault,
                    onChange: this.setFechaFin,
                    value:  documento.docuFechaFin
                  }}
                 // validate={[ required ]}
                  meta={{ touched: true, error: errorFechaFin, warning: false }}
                  value={documento.docuFechaFin}
                  name="txtfechafin"
                  id="txtfechafin" />
                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hFechaFin",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.docuFechaFin}
                  name="hFechaFin"
                  id="hFechaFin" />
              </GridItem>
            </GridContainer>
          </GridItem>
          
        </GridContainer>
        <div style={{ textAlign: "right" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
          >Buscar </Button>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={this.limpiarForm}
          >Limpiar</Button>
        </div>
      </form>
    );
  }
}
RepDocumentoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
RepDocumentoForm = reduxForm({
  form: 'repDocumentoForm',
  validate
})(RepDocumentoForm)

const selector = formValueSelector('repDocumentoForm')
const mapState = (state) => ({
  loading: state.load.loading,
  dscversion: selector(state, 'dscversion')
});
const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    usuarioActions: bindActionCreators(usuarioActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(RepDocumentoForm));