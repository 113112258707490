import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import * as notificationActions from "actions/NotificationActions";
import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";
import Loader from "components/Loader/Loader";

import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import imageBg from "images/banner-login.jpg";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/SidebarLogin";
import loginStyle from "styles/jss/app/containers/loginStyle";
// creates a beautiful scrollbar  
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import LockIcon from "@material-ui/icons/LockOutlined";
import Alert from "components/Snackbar/SnackbarAlert";

import Button from "components/CustomButtons/Button";
import config from "commons/config/AWSConfig";
import { TIPO_MENSAJE, MENSAJE } from 'utils/Constants';
import { sessionService } from 'redux-react-session';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput"; 
import queryString from 'query-string'; 
const required = value => value ? undefined : 'Valor Requerido'


class Login extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      mobileOpen: false,
      usuario: '',
      clave: ''
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  componentDidMount() {
    console.log("login del sistema");
    this.props.reset()
    if (navigator.platform.indexOf("Win") > -1) {
      new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
    const params = new URLSearchParams(this.props.location.search);
    const code = params.get('code');
    if (code) {
      this.validationUserAD();
    }
  }

  validationUserAD() {
    console.log("here login");
    const { loginAD, logoutAD } = this.props.actions;
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    add();
    setTimeout(
      function () {
        loginAD().then(response => {
          show(response.indMostrarMensaje, response.mensaje, response.tipo);
          if (response.tipo === TIPO_MENSAJE.WARNING) {
           // logout();
           logoutAD().then(response => {
             console.log("logout de ususario");
              remove();
            }).catch(err => {
              remove();
            });
          }else{
            remove();
          }
        })
      }, 1000);
  }

  closeNotification = (event, reason) => {
    if (typeof reason === "undefined" || reason !== "clickaway") {
      const { close } = this.props.notificationActions;
      close(false)
    }
  };
  openADFederation = (data) => {
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    let params = queryString.parse(this.props.location.search)
    console.log("data enviada:::", this.state.usuario);
    console.log("data enviada111:::", this.state.clave); 
    add();
    this.props.actions.login({user:this.state.usuario, password:this.state.clave}).then(response => { 
      console.log(response);
      if (response.data.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.props.actions.newPassword({user:response.data, password:this.state.clave}).then(response => { 
          this.props.actions.logout().then(response => {
            show(MENSAJE.IND_MOSTRAR_MENSAJE, "Su Usuario ha sido Activado por ser la primera Vez, Inicie Sesión Nuevamente", TIPO_MENSAJE.INFO);
            this.props.reset()
            remove();
          });
        });
      }else{
        if (response.tipo == TIPO_MENSAJE.SUCCESS){
           this.props.actions.loginTecnico(this.state.usuario, this.state.clave).then(response => {
              console.log("response", response);
              if (response.tipo == TIPO_MENSAJE.SUCCESS){
                if (response.perfil == 3){
                  localStorage.setItem('tkn', "serfac-tecnicos");
                  localStorage.setItem('user', this.state.usuario);
                  localStorage.setItem('userTecnico', JSON.stringify( response));
                  localStorage.setItem('perfil', "T");
                  sessionService.saveUser({"user": this.state.usuario, perfil:"tecnico"})
                }else if (response.perfil == 2){
                  localStorage.setItem('tkn', "serfac-coordinadora");
                  localStorage.setItem('user', this.state.usuario);
                  localStorage.setItem('userCoordinadora', JSON.stringify( response));
                  localStorage.setItem('perfil', "C");
                  sessionService.saveUser({"user": this.state.usuario, perfil:"coordinadora"})
                }else if (response.perfil == 4){
                  localStorage.setItem('tkn', "serfac-ilumi");
                  localStorage.setItem('user', this.state.usuario);
                  localStorage.setItem('userIlumi', JSON.stringify( response));
                  localStorage.setItem('perfil', "I");
                  sessionService.saveUser({"user": this.state.usuario, perfil:"ilumi"})
                }else if (response.perfil == 5){
                  localStorage.setItem('tkn', "serfac-barker");
                  localStorage.setItem('user', this.state.usuario);
                  localStorage.setItem('userBarker', JSON.stringify( response));
                  localStorage.setItem('perfil', "B");
                  sessionService.saveUser({"user": this.state.usuario, perfil:"barker"})
                }else if (response.perfil == 5){
                  localStorage.setItem('tkn', "serfac-indurama");
                  localStorage.setItem('user', this.state.usuario);
                  localStorage.setItem('userIndurama', JSON.stringify( response));
                  localStorage.setItem('perfil', "ID");
                  sessionService.saveUser({"user": this.state.usuario, perfil:"indurama"})
                }else{ // usuario administrador
                  localStorage.setItem('tkn', "serfac-usuarios");  
                  let response = {
                    token: '1a2b3c4d',
                    data: {
                      email: this.state.usuario,
                      firstName: 'serfac',
                      lastName: 'test',
                      perfil:"admin"
                    }
                  };
                  localStorage.setItem('user', this.state.usuario); 
                    sessionService.saveSession( {token: response.token } )
                  sessionService.saveUser(response.data)
                }
                //console.log("response authhh:::",response);
                //console.log("response authhh:2222222::", JSON.stringify( response ));
                localStorage.setItem('idperfilAud', response.perfil);
                localStorage.setItem('idUser', response.id);
                localStorage.setItem('userAud', this.state.usuario);
                localStorage.setItem('productos_serfac', JSON.stringify( response.productos ));
                localStorage.setItem('marcas_serfac', JSON.stringify( response.marcas ));
                localStorage.setItem('tecnicos_serfac', JSON.stringify( response.tecnicos ));


                localStorage.setItem('lineas_serfac', JSON.stringify( response.lineas ));
                localStorage.setItem('recepcionistas_serfac', JSON.stringify( response.recepcionistas ));
                localStorage.setItem('areas_serfac', JSON.stringify( response.areas ));
                localStorage.setItem('tiposervicios_serfac', JSON.stringify( response.tiposervicios ));
                localStorage.setItem('tipocostos_serfac', JSON.stringify( response.tipocostos ));
                localStorage.setItem('tipotrabajos_serfac', JSON.stringify( response.tipotrabajos ));
                localStorage.setItem('tiendas_serfac', JSON.stringify( response.tiendas ));
                localStorage.setItem('ubigeos_serfac', JSON.stringify( response.ubigeos ));
                console.log(response.asedes, "adesessss");
                localStorage.setItem('asedes_serfac', JSON.stringify( response.asedes )); //almacen sedes 


                window.location.href = "/home";
              }else{
                show(MENSAJE.IND_MOSTRAR_MENSAJE, "Los datos ingresados son incorrectos", TIPO_MENSAJE.WARNING);
                this.props.reset()
              }
              console.log("respuetsa del api usuario:", response);
              remove();
          });
        }else{
          // procedemos a buscar en la base de datos para tecnicos 
          show(MENSAJE.IND_MOSTRAR_MENSAJE, "Los datos ingresados son incorrectos", TIPO_MENSAJE.WARNING);
          this.props.reset()
          remove();
        }
      }
    });
      
    //  window.location.href = "/";
    //}
    
   // window.location.href = "/home";
  }

  updateName = (e) => { 
       this.setState({
        [e.target.name]: e.target.value
      });
  }

  render() {
    const { classes, ...rest } = this.props;
    const { handleSubmit  } = this.props;
    const {usuario, clave} = this.state;
    return (
      <form  style={{ width: "100%" }}  >
        <div className={classes.wrapper}>
          <Alert message={this.props.message} color={this.props.color}
            place={this.props.place} open={this.props.open} closeNotification={(a, b) => this.closeNotification(a, b)} />
          <Loader loading={this.props.loading} />
          <Sidebar
            image={imageBg}
            handleDrawerToggle={this.handleDrawerToggle}
            handleItemListCollapse={this.handleItemListCollapse}
            mobileOpen={this.state.mobileOpen}
            {...rest}
          />
          <div className={classes.mainPanel} ref="mainPanel">
            <div className={classes.loginBar}>
              <div className={classes.logo}>
                <a className={classes.logoLink}>
                  <Typography variant="h4" align="center" color="primary" className={classes.bold}>SERFAC  <br/>SPIRIT</Typography>
                </a>
              </div>
            </div>
            <div className={classes.content}>
              <div className={classes.container}>
                <Paper className={classes.paper}>
                  <Avatar className={classes.avatar}>
                    <LockIcon />
                  </Avatar>

                  <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        
                        <GridItem xs={12} sm={12} md={6}>
                          <Field labelText="Usuario"
                          onChange={(event) => {
                              this.updateName(event);
                            }}
                            formControlProps={{
                              fullWidth: true,
                              maxLength: "10",
                              margin: "normal",
                              style: { marginTop: "0px" }
                            }}
                            inputProps={{
                              type: "text",
                              name: "usuario",
                              min: "1",
                              maxLength: "250"
                            }}
                             
                            component={CustomInput}
                            validate={[required]}
                            value={usuario}
                            maxLength="10"
                            name="usuario"
                            id="usuario" />
                            
                        </GridItem>
                         <GridItem xs={12} sm={12} md={6}>
                         <Field labelText="Clave"
                           onChange={(event) => {
                                this.updateName(event);
                              }}
                            formControlProps={{
                              fullWidth: true,
                              maxLength: "10",
                              margin: "normal",
                              style: { marginTop: "0px" }
                            }}
                            inputProps={{
                              type: "password",
                              name: "clave",
                              min: "1",
                              maxLength: "250"
                            }}
                             
                            component={CustomInput}
                            validate={[required]}
                            value={clave}
                            maxLength="10"
                            name="clave"
                            id="clave" />
                        </GridItem>
                      </GridContainer>
                    </GridItem>

 
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary" 
                    onClick={this.openADFederation}
                    className={classes.submit}
                  >Ingresar</Button>
                </Paper>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </form>
    );
  }
}

const { object } = PropTypes;

Login.propTypes = {
  actions: object.isRequired,
  classes: PropTypes.object.isRequired,
};

Login = reduxForm({
  form: 'login' 
})(Login)


const mapState = (state) => ({
  loading: state.load.loading,
  message: state.notification.message,
  color: state.notification.color,
  place: state.notification.place,
  open: state.notification.open
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};
Login = withStyles(loginStyle)(Login);

export default withRouter(connect(mapState, mapDispatch)(Login));