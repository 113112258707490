import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';
import CustomTextArea from "components/CustomInput/CustomTextArea";
import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";
import * as formActions from "actions/FormActions";
import { email, required} from 'utils/FormValidation';
import { ESTADO } from 'utils/Constants'; 
import Grid from "components/Grid/RepuestoAlmacenDirGrid";
import UploadImages from "components/File/FileUpload";
import { getFormatDate, getRestarDias } from 'utils/Functions';
import { DATA_DISTRITOS, DATA_ESTADO_REPUESTO, DATA_TIPO_TRABAJO, DATA_LINEAS, DATA_TIENDAS, DATA_ESTADOS_DIALOG_SEGUIMIENTO_DOMICILIO, DATA_AREA, DATA_ARTEFACTO, DATA_MARCA, DATA_TIPO_SERVICIO, DATA_ESTADOS_DIAGNOSTICO_DOMICLIO_TECNICO, DATA_TECNICOS} from 'utils/GeneralData';
class MntDomicilioRutaDiaForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
         showGuadar: '',
         tipsTrabajo: [],
         documento:  { 
            tecnico: '',
            modelo:'',
            serie:'',
            marca:'',
            producto:'',
            recomendacion:'',
            estado: '',
            precio: '',
            vertodogth: '',
            stsusuario: '',
            id: '',
            clieId: '',
            codestado: '',
            seguimiento: '',
            numeroservicio: '',
            clieNumero: '',
            email: '',
            clieUbigeo: '',
            tipoServicio: '',
            motivo: '',
            linea: '',
            area:'',
            moneda: 'SOL',
            artefacto: '',
            horaservicio: '',
            fechaIngreso: '',
            docuFechaIni: '',
            docuFechaVenta: '',
            tipoTrabajo: '',
            hora: '',
            idlocal:0,
            celular: '',
            blockDisabled: false,
            obscliente: '',
            doccaja: '',
            tienda: ''
          },
          lineas: [  ],
          areas: [],
          marcas: [ ],
          estados: [],
          tiendas:[],
          monedas: [{
              "id": "SOL",
              "valor": "SOLES"
            },
            {
              "id": "USD",
              "valor": "DOLARES"
            }
          ],
          companias: [ ],
          artefactos:[],
          estadoArtefactos: [ ],
          tipoServicios: [],
          repuestos: [],
          errorFecha: '',
          errorFechaFin: '',
          idlocal: 0,
          celular: '',
          tecnicos: [],
      }
      
  }

  componentDidMount() {
      this.props.onRef(this);
      const {   documento } = this.props;
      console.log("datos que llegaron gagagagag" , documento);
      this.props.change('modelo', documento.modelo)
      this.props.change('serie', documento.serie)
      this.props.change('marca', documento.marcacodigo)
      this.props.change('recomendacion', documento.recomendacion)
      this.props.change('seguimiento', documento.seguimiento)
      
      this.props.change('hId', documento.id)
      this.props.change('estado', documento.estado)
      this.props.change('ubicacion', documento.ubicacion)
      this.props.change('nombre', documento.nombre)
      this.props.change('telefono', documento.telefono)
      this.props.change('direccion', documento.direccion)
      this.props.change('numeroservicio', documento.numeroservicio)
      this.props.change('clieNumero', documento.numdoccliente)
      this.props.change('clieId', documento.clienteid) 
      this.props.change('email', documento.email)
      this.props.change('clieUbigeo', documento.ubigeo) 
      this.props.change('tipoServicio', ""+documento.codtipservicio) 
      this.props.change('moneda',  documento.moneda) 

      this.props.change('doccaja',  documento.doccaja) 


      this.props.change('artefacto',  documento.artefacto)
      this.props.change('motivo', documento.motivo)
      this.props.change('area', documento.area)
      this.props.change('linea', documento.linea)
      this.props.change('horaservicio',  documento.hora) 


      this.props.change('hFechaFin', documento.fechacompra.trim())
      this.props.change('hFechaInicio', documento.fechaatencion.trim())
      this.props.change('tipoTrabajo',  documento.tipotrabajo)  
      this.props.change('observacion',  documento.observacion)

      this.props.change('idlocal',  documento.idlocal)
      this.props.change('celular',  documento.celular)

      this.props.change('obscliente',  documento.obscliente)

      

      this.state.documento.docuFechaVenta = documento.fechacompra.trim()
      this.state.documento.docuFechaIni = documento.fechaatencion.trim()
      this.state.documento.tipoTrabajo = documento.tipotrabajo;
      this.state.documento.observacion = documento.observacion;
      this.state.documento.idlocal = documento.idlocal;
      this.state.documento.celular = documento.celular;

      this.state.documento.clieUbigeo = documento.ubigeo;
      this.state.documento.tipoServicio = documento.codtipservicio;
      this.state.documento.moneda = documento.moneda;
      this.state.documento.estado = documento.estado;

      this.state.documento.artefacto = documento.artefacto;
      this.state.documento.area = documento.area;
      this.state.documento.marca = documento.marcacodigo;
      this.state.documento.linea = documento.linea; 
      this.state.documento.tecnico = documento.tecnico != null ? documento.tecnico : ''; 
      console.log("lectura de imagenes") 
      documento.fechaIngreso = documento.fechaingreso
      this.state.documento.fechaIngreso = documento.fechaingreso; 
      this.state.documento.id = documento.id; 
      this.state.documento.horaservicio = documento.hora; 
      this.state.documento.doccaja = documento.doccaja; 

      this.UploadImages.listarImagenesPorId(documento);
      this.state.lineas = JSON.parse(localStorage.getItem("lineas_serfac")); //DATA_LINEAS;
      this.state.areas = JSON.parse(localStorage.getItem("areas_serfac"));  //DATA_AREA;
      this.state.marcas = JSON.parse(localStorage.getItem("marcas_serfac")); //DATA_MARCA;
      this.state.companias = JSON.parse(localStorage.getItem("ubigeos_serfac")); //DATA_DISTRITOS;
      this.state.artefactos = JSON.parse(localStorage.getItem("productos_serfac")); //DATA_ARTEFACTO;
      this.state.tipoServicios = JSON.parse(localStorage.getItem("tiposervicios_serfac")); // DATA_TIPO_SERVICIO;
      this.state.estados = DATA_ESTADO_REPUESTO; 
      this.state.estadoArtefactos = DATA_ESTADOS_DIALOG_SEGUIMIENTO_DOMICILIO;
      this.state.tipsTrabajo = JSON.parse(localStorage.getItem("tipotrabajos_serfac")); //DATA_TIPO_TRABAJO;
      this.state.tiendas = JSON.parse(localStorage.getItem("tiendas_serfac")); //DATA_TIENDAS;
      if (localStorage.getItem("tkn") == "serfac-tecnicos" ){
        this.state.showGuadar = ''
        this.state.estadoArtefactos =  DATA_ESTADOS_DIAGNOSTICO_DOMICLIO_TECNICO
        this.state.documento.blockDisabled = true
      }

      if (localStorage.getItem("tkn") == "serfac-ilumi" || localStorage.getItem("tkn") == "serfac-indurama"  || localStorage.getItem("tkn") == "serfac-barker" ){
        this.state.showGuadar = 'None' 
      }

      this.state.tecnicos = JSON.parse(localStorage.getItem("tecnicos_serfac")); //DATA_TECNICOS

      this.props.change('tecnico',  documento.idtecnico === 0 ? null : documento.idtecnico)
      this.state.documento.tecnico = documento.idtecnico === 0 ? null : documento.idtecnico

      this.state.documento.obscliente = documento.obscliente;
      this.state.documento.tienda = documento.tienda; 
      this.props.change('tienda',  documento.tienda)

    /*  this.state.documento.estado = {
        "id": "2",
        "valor": "COTIZADO"
      } */
  }

  extraerRows = ( ) => {
    let rowsRepuestos = this.Grid.extraerRows();
    console.log(rowsRepuestos);
    return rowsRepuestos;
  }  


  

  setFechaIni = (event) => {
    let fecha = event.target.value;
    this.setState({ errorFechaFin: false });
    this.setState({ errorFecha: false });
    this.state.documento.docuFechaIni = fecha;
    this.props.change('hFechaInicio', fecha) 
  }

  setFechaVenta = (event) => {
    let fecha = event.target.value;
     
      this.setState({ errorFecha: false });
      this.setState({ errorFechaFin: false });
      this.state.documento.docuFechaVenta = fecha;
      this.props.change('hFechaFin', fecha) 
  }


  getSelectValue = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    this.setState({ documento: documento });
  }

  render() {
    const { handleSubmit, classes, estados, toggleDialogRepuesto, repuestos } = this.props;
    const { documento , estadoArtefactos, monedas, lineas, marcas, areas, artefactos, showGuadar, errorFecha, errorFechaFin} = this.state;

    var perfilesactivos = [];
    

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%", margin: 8 * 3 }}>
        <GridContainer>

          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
               <Field labelText="Número Documento"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "clieNumero",
                    name: "clieNumero",
                    min: "1"
                  }}
                  component={CustomInput}
                  validate={[required]}
                  value={documento.clieNumero}
                  name="clieNumero"
                  id="clieNumero" /> 
              </GridItem> 
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Nombre del Cliente"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "nombre"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 50)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^a-zA-ZñÑáéíóúÁÉÍÓÚ\s]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  value={documento.nombre} 
                  name="nombre"
                  id="nombre" />
              </GridItem>
              
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Celular"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "celular",
                      name: "celular",
                      min: "1"
                    }}
                    
                    component={CustomInput}
                    value={documento.celular}
                    name="celular"
                    id="celular" /> 
 
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Telefono"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "telefono"
                  }}
                  
                  component={CustomInput}
                  value={documento.telefono} 
                  name="telefono"
                  id="telefono" />
              </GridItem>  
                                      
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
                        
              <GridItem xs={12} sm={12} md={3}>
             
                   <Field labelText="Distrito"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      autoComplete: "clieUbigeo",
                      name: "clieUbigeo"
                    }}
                    multiple={false}
                    data={this.state.companias}
                    component={CustomSelect}
                    validate={[required]}
                    valor={documento.clieUbigeo}
                    getValue={this.getSelectValue}
                    val="id"
                    txt="valor"
                    name="clieUbigeo"
                    id="clieUbigeo" /> 
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                 <Field labelText="Email"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "email",
                    min: "1"
                  }}
                  
                  component={CustomInput}
                  value={documento.email}
                  name="email"
                  id="email" />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                  <Field labelText="Dirección"
                      formControlProps={{
                        fullWidth: true,
                        margin: "normal",
                        style: { marginTop: "0px" }
                      }}
                      inputProps={{
                        name: "direccion"
                      }}
                    
                      component={CustomInput}
                      value={documento.direccion} 
                      name="direccion"
                      id="direccion" />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Moneda"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "moneda",
                    name: "moneda"
                  }}
                  multiple={false}
                  data={this.state.monedas}
                  component={CustomSelect}
                  valor={documento.moneda}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="id"
                  txt="valor"
                  name="moneda"
                  id="moneda" />
              </GridItem>

            </GridContainer>
          </GridItem>


          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Modelo"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "modelo"
                  }}
                   
                  component={CustomInput}
                  value={documento.modelo} 
                  name="modelo"
                  id="modelo" />
              </GridItem>  
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Serie"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    name: "serie"
                  }}
                   
                  component={CustomInput}
                  value={documento.serie} 
                  name="serie"
                  id="serie" />

                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hId",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.id}
                  name="hId"
                  id="hId" />


                  <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "clieId",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.clieId}
                  name="clieId"
                  id="clieId" />

              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <Field labelText="Marca"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "marca",
                    name: "marca"
                  }}
                  multiple={false}
                  data={this.state.marcas}
                  component={CustomSelect}
                  valor={documento.marca}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="id"
                  txt="valor"
                  name="marca"
                  id="marca" />
              </GridItem>                         
            </GridContainer>
          </GridItem>


          
 
           
          
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                
                <Field labelText="Tipo de Servicio"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      autoComplete: "tipoServicio",
                      name: "tipoServicio"
                    }}
                    multiple={false}
                    data={this.state.tipoServicios}
                    component={CustomSelect}
                    valor={documento.tipoServicio}
                    getValue={this.getSelectValue}
                    validate={[required]}
                    val="id"
                    txt="valor"
                    name="tipoServicio"
                    id="tipoServicio" />
              </GridItem>
                    
              <GridItem xs={12} sm={12} md={3}>
             
                   <Field labelText="Motivo"
                      formControlProps={{
                        fullWidth: true,
                        margin: "normal",
                        style: { marginTop: "0px" }
                      }}
                      inputProps={{
                        type: "motivo",
                        name: "motivo",
                        min: "1"
                      }}
                      
                      component={CustomInput}
                      value={documento.motivo}
                      name="motivo"
                      id="motivo" /> 
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                 <Field labelText="Area Asignada"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "area",
                    name: "area"
                  }}
                  multiple={false}
                  data={this.state.areas}
                  component={CustomSelect}
                  valor={documento.area}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="id"
                  txt="valor"
                  name="area"
                  id="area" />
              </GridItem>
                
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Artefacto"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "artefacto",
                    name: "artefacto"
                  }}
                  multiple={false}
                  data={this.state.artefactos}
                  component={CustomSelect}
                  valor={documento.artefacto}
                  getValue={this.getSelectValue}
                  val="id"
                  validate={[required]}
                  txt="valor"
                  name="artefacto"
                  id="artefacto" />
              </GridItem>
                
            </GridContainer>
          </GridItem>





          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
                        
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Observación"
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            style: { marginTop: "0px" }
                          }}
                          inputProps={{
                            type: "observacion",
                            name: "observacion",
                            rows: 9,
                            min: "1"
                          }} 
                          component={CustomInput}
                          value={documento.observacion}
                          name="observacion"
                          id="observacion" /> 
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Field labelText="Tipo de Trabajo"
                          formControlProps={{
                            fullWidth: true,
                            margin: "normal",
                            style: { marginTop: "0px" }
                          }}
                          inputProps={{
                            autoComplete: "tipoTrabajo",
                            name: "tipoTrabajo"
                          }}
                          multiple={false}
                          data={this.state.tipsTrabajo}
                          component={CustomSelect}
                          valor={documento.tipoTrabajo}
                          getValue={this.getSelectValue}
                          validate={[required]}
                          val="id"
                          txt="valor"
                          name="tipoTrabajo"
                          id="tipoTrabajo" />
                    </GridItem> 
                
              <GridItem xs={12} sm={12} md={3}>
              <CustomInput labelText="Fecha Atención"
                                formControlProps={{
                                  fullWidth: true,
                                  margin: "normal",
                                  style: { marginTop: "0px" }
                                }}
                                inputProps={{
                                  autoComplete: "docuFechaIni",
                                  name: "docuFechaIni",
                                  type: "date",
                                  //defaultValue: fecdefault,
                                  onChange: this.setFechaIni,
                                  value:  documento.docuFechaIni,
                                  format: 'DD-MM-YYYY'
                                }}
                               // validate={[ required ]}
                                meta={{ touched: true, error: errorFecha, warning: false }}
                                //value={documento.docuFechaIni}
                                //name="docuFechaIni"
                               // id="docuFechaIni"
                                 />
                                 <Field
                      formControlProps={{
                        fullWidth: false,
                        margin: "normal",
                        style: { marginTop: "0px" }
                      }}
                      inputProps={{
                        type: "hidden",
                        name: "hFechaInicio",
                        min: "1"
                      }}
                      component={CustomInput}
                      value={documento.docuFechaIni}
                      name="hFechaInicio"
                      id="hFechaInicio" /> 

                <Field
                      formControlProps={{
                        fullWidth: false,
                        margin: "normal",
                        style: { marginTop: "0px" }
                      }}
                      inputProps={{
                        type: "hidden",
                        name: "idlocal",
                        min: "1"
                      }}
                      component={CustomInput}
                      value={documento.idlocal}
                      name="idlocal"
                      id="idlocal" /> 

              </GridItem>

              <GridItem xs={12} sm={12} md={3}> 
              <CustomInput labelText="Fecha de Compra"
                                  formControlProps={{
                                    fullWidth: true,
                                    margin: "normal",
                                    style: { marginTop: "0px" }
                                  }}
                                  inputProps={{
                                    autoComplete: "docuFechaVenta",
                                    name: "docuFechaVenta",
                                    type: "date",
                                    //defaultValue: fecdefault,
                                    onChange: this.setFechaVenta,
                                    value:  documento.docuFechaVenta,
                                    format: 'DD-MM-YYYY'
                                  }}
                                 // validate={[ required ]}
                                  meta={{ touched: true, error: errorFecha, warning: false }}
                                  //value={documento.docuFechaIni}
                                  //name="docuFechaIni"
                                 // id="docuFechaIni"
                                   />
                                <Field
                                formControlProps={{
                                  fullWidth: false,
                                  margin: "normal",
                                  style: { marginTop: "0px" }
                                }}
                                inputProps={{
                                  type: "hidden",
                                  name: "hFechaFin",
                                  min: "1"
                                }}
                                component={CustomInput}
                                value={documento.docuFechaVenta}
                                name="hFechaFin"
                                id="hFechaFin" />
              </GridItem>

                
            </GridContainer>
          </GridItem>



          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                 <Field labelText="Número de O/S"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "numeroservicio" 
                  }}                    
                  component={CustomInput}
                  value={documento.numeroservicio}
                  name="numeroservicio"
                  id="numeroservicio" />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                  <Field labelText="Tecnico"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    disabled: documento.blockDisabled,
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "tecnico",
                    name: "tecnico"
                  }}
                  
                  multiple={false}
                  
                  data={this.state.tecnicos}
                  component={CustomSelect}
                  valor={documento.tecnico}
                  getValue={this.getSelectValue}
                  val="id"
                  txt="valor"
                  name="tecnico"
                  id="tecnico" />
                </GridItem>

              <GridItem xs={12} sm={12} md={3}>

                <Field labelText="Estado"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    autoComplete: "estado",
                    name: "estado"
                  }}
                  multiple={false}
                  data={estadoArtefactos}
                  component={CustomAutocomplete}
                  valor={documento.estado}
                  getValue={this.getSelectValue}
                  validate={[required]}
                  val="id"
                  txt="valor"
                  name="estado"
                  id="estado" />
              </GridItem>
                 
              

              <GridItem xs={12} sm={12} md={3}>
                 <Field labelText="Hora: "
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "horaservicio" 
                  }}                    
                  component={CustomInput}
                  value={documento.horaservicio}
                  name="horaservicio"
                  id="horaservicio" />
              </GridItem>

              
            </GridContainer>
          </GridItem>                      
          

           
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                 <Field labelText="Documentos Caja"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "text",
                    name: "doccaja" 
                  }}                    
                  component={CustomInput}
                  value={documento.doccaja}
                  name="doccaja"
                  id="doccaja" />
              </GridItem>   
              <GridItem xs={12} sm={12} md={6}>
                      <Field labelText="Tienda"
                        formControlProps={{
                          fullWidth: true,
                          margin: "normal",
                          style: { marginTop: "0px" }
                        }}
                        inputProps={{
                          autoComplete: "tienda",
                          name: "tienda"
                        }}
                        multiple={false}
                        data={this.state.tiendas}
                        component={CustomSelect}
                        valor={documento.tienda}
                        getValue={this.getSelectValue}
                        validate={[required]}
                        val="id"
                        txt="valor"
                        name="tienda"
                        id="tienda" />
                    </GridItem>

            </GridContainer>
          </GridItem>                      
          
         
          
          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <div style={{ padding: "15px" }}>  Recomendaciones del tecnico: {documento.tecnico} </div>
            <GridItem xs={12} sm={12} md={12}>
                <Field labelText=""
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "textarea",
                      name: "recomendacion",
                      rows: 6,
                      min: "1"
                    }}
                    component={CustomTextArea}
                    value={documento.recomendacion}
                    name="recomendacion"
                    id="recomendacion" /> 
              </GridItem>                
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
            <div style={{ padding: "15px" }}> Seguimiento Coordinadora </div>
            <GridItem xs={12} sm={12} md={12}>
                <Field labelText=""
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "textarea",
                      name: "seguimiento",
                      rows: 6,
                      min: "1"
                    }}
                    component={CustomTextArea}
                    value={documento.seguimiento}
                    name="seguimiento"
                    id="seguimiento" /> 
              </GridItem>                
            </GridContainer>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Field labelText="Observaciones Cliente"
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      style: { marginTop: "0px" }
                    }}
                    inputProps={{
                      type: "text",
                      name: "obscliente"  
                    }}
                     
                    component={CustomInput}
                    value={documento.obscliente}
                    name="obscliente"
                    id="obscliente" />
                </GridItem>
                
              </GridContainer>
          </GridItem> 


 
          
        </GridContainer>
        <div style={{ padding: "15px" }}>  Repuestos</div>
          <GridItem xs={12} sm={12} md={12} >
              <GridContainer> 
                <GridItem xs={12} sm={12} md={12}>
                  <Grid onRef={ref => (this.Grid = ref)}  usuario={this.state.usuario} estados={this.state.estados} repuestos={repuestos}/> 
                </GridItem>
              </GridContainer>
          </GridItem>

          

          <GridItem xs={12} sm={12} md={12}  >
              <GridContainer> 
                <GridItem xs={12} sm={12} md={12}>
                <UploadImages documento={this.state.documento}   onRef={ref => (this.UploadImages = ref)}/>
                </GridItem>
              </GridContainer>
          </GridItem>

        
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
              {/* <div style={{ textAlign: "right" }}> */}
               
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ display: showGuadar }}
                className={classes.submit}
              >Guardar</Button>
               
              <Button onClick={() => toggleDialogRepuesto()}>Cerrar</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </form>
    );
  }
}

MntDomicilioRutaDiaForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
MntDomicilioRutaDiaForm = reduxForm({
  form: 'mntRepuestoForm',
  enableReinitialize: true
})(MntDomicilioRutaDiaForm)
const mapState = (state) => ({
  loading: state.load.loading,
  initialValues: state.formApp.data
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(MntDomicilioRutaDiaForm));
