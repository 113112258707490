import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "components/CustomInput/CustomSelect";
import CustomAutocomplete from "components/CustomInput/CustomAutocomplete";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Field, reduxForm } from 'redux-form';
import CustomTextArea from "components/CustomInput/CustomTextArea";
import * as loadActions from "actions/LoadActions";
import * as notificationActions from "actions/NotificationActions";
import * as formActions from "actions/FormActions";
import { email, required} from 'utils/FormValidation';
 class MntDomicilioRutaDiaForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
         showGuadar: '',
         tipsTrabajo: [],
         documento:  { 
          idficha: '',
            importe: 0,
            tecnico: '',
            modelo:'',
            serie:'',
            marca:'',
            producto:'',
            recomendacion:'',
            estado: '',
            precio: '',
            vertodogth: '',
            stsusuario: '',
            id: '',
            clieId: '',
            codestado: '',
            seguimiento: '',
            numeroservicio: '',
            clieNumero: '',
            email: '',
            clieUbigeo: '',
            tipoServicio: '',
            motivo: '',
            linea: '',
            area:'',
            moneda: 'SOL',
            artefacto: '',
            horaservicio: '',
            fechaIngreso: '',
            docuFechaIni: '',
            docuFechaVenta: '',
            tipoTrabajo: '',
            hora: '',
            idlocal:0,
            celular: '',
            blockDisabled: false,
            obscliente: '',
            doccaja: '',
            tienda: ''
          },
          lineas: [  ],
          areas: [],
          marcas: [ ],
          estados: [],
          tiendas:[],
          monedas: [{
              "id": "SOL",
              "valor": "SOLES"
            },
            {
              "id": "USD",
              "valor": "DOLARES"
            }
          ],
          companias: [ ],
          artefactos:[],
          estadoArtefactos: [ ],
          tipoServicios: [],
          repuestos: [],
          errorFecha: '',
          errorFechaFin: '',
          idlocal: 0,
          celular: '',
          tecnicos: [],
      }
      
  }

  componentDidMount() {
      this.props.onRef(this);
      const {   documento } = this.props;
      console.log("datos que llegaron gagagagag" , documento);
     
      
      this.props.change('hId', documento.id)
      this.props.change('idficha', documento.idficha)

      this.props.change('importe', documento.importe)
       
      this.state.documento.importe = documento.importe;
      this.state.documento.idficha = documento.idficha;

      

      this.state.tecnicos = JSON.parse(localStorage.getItem("tecnicos_serfac")); //DATA_TECNICOS

      this.props.change('tecnico',  documento.idtecnico === 0 ? null : documento.idtecnico)
      this.state.documento.tecnico = documento.idtecnico === 0 ? null : documento.idtecnico

      if (localStorage.getItem("tkn") == "serfac-tecnicos" ){
        this.state.showGuadar = 'None' 
      }

      if (localStorage.getItem("tkn") == "serfac-ilumi" || localStorage.getItem("tkn") == "serfac-indurama"|| localStorage.getItem("tkn") == "serfac-barker"  ){
        this.state.showGuadar = 'None' 
      }

 
  }

    

  setFechaIni = (event) => {
    let fecha = event.target.value;
    this.setState({ errorFechaFin: false });
    this.setState({ errorFecha: false });
    this.state.documento.docuFechaIni = fecha;
    this.props.change('hFechaInicio', fecha) 
  }

  setFechaVenta = (event) => {
    let fecha = event.target.value;
     
      this.setState({ errorFecha: false });
      this.setState({ errorFechaFin: false });
      this.state.documento.docuFechaVenta = fecha;
      this.props.change('hFechaFin', fecha) 
  }


  getSelectValue = (valor, name) => {
    var documento = this.state.documento
    documento[name] = valor;
    this.setState({ documento: documento });
  }

  render() {
    const { handleSubmit, classes, toggleDialogRepuesto } = this.props;
    const { documento, showGuadar} = this.state;
 
    

    return (
      <form onSubmit={handleSubmit} style={{ width: "100%", margin: 8 * 3 }}>
        <GridContainer>

          <GridItem xs={12} sm={12} md={12}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
               <Field labelText="Pasaje"
                  formControlProps={{
                    fullWidth: true,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "pasaje",
                    name: "pasaje",
                    min: "1"
                  }}
                  normalize={(val, prevVal) => {
                    if (val.length > 10)
                      return prevVal;
                    
                    if (val) {
                      return (!/[^0-9./]/.test(val)) ? val : prevVal
                    }
                    return val;
                  }}
                  component={CustomInput}
                  validate={[required]}
                  value={documento.importe}
                  name="importe"
                  id="importe" /> 

              <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "hId",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.id}
                  name="hId"
                  id="hId" />

            <Field
                  formControlProps={{
                    fullWidth: false,
                    margin: "normal",
                    style: { marginTop: "0px" }
                  }}
                  inputProps={{
                    type: "hidden",
                    name: "idficha",
                    min: "1"
                  }}
                  component={CustomInput}
                  value={documento.idficha}
                  name="idficha"
                  id="idficha" />

              </GridItem> 
               
               
                                      
            </GridContainer>
          </GridItem>
 
        
          
        </GridContainer>
         
        
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
              {/* <div style={{ textAlign: "right" }}> */}
               
              <Button
                type="submit"
                variant="contained"
                color="primary" 
                style={{ display: showGuadar }}
                className={classes.submit}
              >Guardar</Button>
               
              <Button onClick={() => toggleDialogRepuesto()}>Cerrar</Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </form>
    );
  }
}

MntDomicilioRutaDiaForm.propTypes = {
  classes: PropTypes.object.isRequired,
};
MntDomicilioRutaDiaForm = reduxForm({
  form: 'mntRepuestoForm',
  enableReinitialize: true
})(MntDomicilioRutaDiaForm)
const mapState = (state) => ({
  loading: state.load.loading,
  initialValues: state.formApp.data
});
const mapDispatch = (dispatch) => {
  return {
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch),
    formActions: bindActionCreators(formActions, dispatch)
  };
};
export default withRouter(connect(mapState, mapDispatch)(MntDomicilioRutaDiaForm));
