import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  SelectionState, IntegratedSelection, IntegratedSorting, SortingState, PagingState, CustomPaging
} from '@devexpress/dx-react-grid';
import {
  Grid, VirtualTable, Table,
  TableHeaderRow, PagingPanel, TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import * as documentoActions from "actions/DocumentoActions";
import * as notificationActions from "actions/NotificationActions";
//import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";

import {
  TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE, MENSAJE_GENERAL
} from 'utils/Constants';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import AnularIcon from '@material-ui/icons/Close';
import List from '@material-ui/icons/List'; 
//import { s2ab } from 'utils/Functions'; 
import excelPng from "images/icons/excel.png"; 
import { s2ab, getBase64FromFile } from 'utils/Functions';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer"; 
import { blue, red } from '@material-ui/core/colors';

import PasajeDialog from "components/Dialog/PasajeDialog";
import logo from "images/logo-rimac-r.png";
import JsBarcode from 'jsbarcode';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import config from "commons/config/AWSConfig";
import DOMPurify from 'dompurify';

const getRowId = row => row.id;

class BsqDomPasajeGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGuadar: 'flex',
      columns: [
        { name: 'id', title: ' id' },
        { name: 'idficha', title: 'Num. Ficha' },
        { name: 'fechaatencion', title: 'F. Atención' },
        { name: 'estadoname', title: 'Estado' }, 
        { name: 'cliente', title: 'Cliente' },
        { name: 'distrito', title: 'Distrito' },
        { name: 'importe', title: 'Pasaje' },   
        { name: 'tecnico', title: 'Tecnico' }, 
        { name: 'archivo', title: 'Acción' }
      ],
      tableColumnExtensions: [
        { columnName: 'id', width: 70 },
        { columnName: 'idficha', width: 150 },
        { columnName: 'fechaatencion', width: 100, wordWrapEnabled: true },
        { columnName: 'estadoname', width: 150 },  
        { columnName: 'importe', width: 100 },
        { columnName: 'cliente', width: 100, wordWrapEnabled: true },
        { columnName: 'distrito', width: 100, wordWrapEnabled: true },
        { columnName: 'tecnico', width: 140, wordWrapEnabled: true },
        { columnName: 'archivo', width: 200 },
      ],
      columnsSeguimiento: [
        { name: 'fecha_seguimiento', title: ' Fecha' },
        { name: 'cdr_code', title: 'Código ' },
        //{ name: 'estado_seguimiento', title: 'Estado' },
        { name: 'cdr_nota', title: 'Nota' },
        { name: 'cdr_observacion', title: 'Observación' }
      ],
      tableColumnSeguimientoExtensions: [
        { columnName: 'fecha_seguimiento', width: 120, wordWrapEnabled: true },
        { columnName: 'cdr_code', width: 80, wordWrapEnabled: true },
        //{ columnName: 'estado_seguimiento', width: 100, wordWrapEnabled: true },
        { columnName: 'cdr_nota', width: 250, wordWrapEnabled: true },
        { columnName: 'cdr_observacion', width: 250, wordWrapEnabled: true }
      ],
      headersCsv: [{
        id: 'id',
        display: 'id'
      }, {
        id: 'fecha_seguimiento',
        display: 'Fecha'
      }, {
        id: 'cdr_code',
        display: 'Codigo'
      },/* {
        id: 'estado_seguimiento',
        display: 'Estado'
      },*/ {
        id: 'cdr_nota',
        display: 'Nota'
      }, {
        id: 'cdr_observacion',
        display: 'Observacion'
      }],
      rows: [],
      //sorting: [{ columnName: 'docu_fecha', direction: 'asc' }],
      sorting: [],
      currentPage: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      showSeguimientoDialog: false,
      seguimientoRows: [],
      selection: [],
      showUsuarioDialog: false,
      repuestos: [],
      documento:{}
    };

    this.changeSorting = sorting => this.setState({ sorting });
    this.changeSelection = selection => {
      const { show } = this.props.notificationActions;
      // console.log(selection);
      //console.log(selection.length);
      if (selection.length > 250){
        show("1", "La cantidad de archivos a descargar debe ser menor a 250", TIPO_MENSAJE.WARNING);
        //console.log("error la cantidad de archivos a descargar debe ser menor a 250");
      }
      this.setState({ selection: selection });
    }
    //this.changeCurrentPage = currentPage => this.setState({ currentPage });
    //this.changePageSize = pageSize => this.setState({ pageSize });

  }

  changeCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage })
    this.buscarDocumentos(currentPage);
  }

  changePageSize = (pageSize) => {
    const { totalCount, currentPage: stateCurrentPage } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(stateCurrentPage, totalPages - 1);

    this.setState({
      pageSize: pageSize,
      currentPage: currentPage
    });
    this.buscarDocumentos(currentPage, pageSize);
  }

  componentDidMount() {
    
    this.buscarDocumentos();
    this.state.selection = [];
    this.props.onRef(this);
     
    if (localStorage.getItem("tkn") == "serfac-tecnicos"){
      this.state.showGuadar = 'None'
    }
    //this.buscarDocumentos();
  }

  limpiarForm = () => {
    //this.props.reset();
    var documento = {
      docuNumero: '',
      tipoDocumento: '',
      clieNumero: '',  //es ruc
      docuFechaIni: '',
      docuFechaFin: '',
      estado: '',
      clieNombre: '', //razon Social
      cdr: ''
    };
    
    this.setState({ rows: [] });
    this.setState({ documento: documento });
  }

  buscarDocumentos(page, size) {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    const { currentPage, pageSize } = this.state;
    add();
    page = page ? page : currentPage;
    size = size ? size : pageSize;

    if (page === -1) {
      page = 0;
      this.limpiarForm();
    }
    if (page === currentPage) { page = 0; }
    //validamos filtros
     
    let {documento} = this.props;
    documento.operacion = "listarPasaje";
    documento.tiporegistro = "D";

    if (localStorage.getItem("tkn") == "serfac-ilumi"){ 
      documento.area = 15; // siempre para ilumi 15
    }

    if (localStorage.getItem("tkn") == "serfac-barker"){ 
      documento.area = 20; // siempre para ilumi 20
    }

    if (localStorage.getItem("tkn") == "serfac-indurama"){ 
      documento.area = 18;  
    }
    

    this.props.actions.consultarFichas(documento, page + 1, size).then(response => { 
      if (response.status === 200) {
        this.setState({ rows: response.data, totalCount: response.totalCount })
      } else {
        if (response.data != null && response.data == 'dato invalido'){
          show(response.indMostrarMensaje, "Ingrese información valida para la busqueda", TIPO_MENSAJE.WARNING);
        }else{
          show(response.indMostrarMensaje, response.mensaje, response.tipo);
        }
      }
      remove();
    })
  }

 
 

 

  Cell = (props) => {
    const { column, row } = props;
    
    if (column.name === 'estadoname' &&  row.estado == 0) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="secondary" avatar={<Avatar>R</Avatar>} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 10) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} icon={<DoneIcon  />} color="primary" avatar={<Avatar>E</Avatar>} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 11) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>R</Avatar>} style={{background: "#e29b51", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 2) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>C</Avatar>} style={{background: "#00ff7e" , color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 3) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>A</Avatar>} style={{background: "#c57bc0", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 9) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} icon={<DoneIcon  />} color="yellow" avatar={<Avatar>D</Avatar>} style={{background: "#3a115f", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 13) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>R</Avatar>} style={{background: "#16886e", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 14) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>N</Avatar>} style={{background: "rgb(111 76 56)", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' ) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname}   />      
          
        </TableCell>
      );
     }

    if (column.name === 'archivo' ) {
      return (
        <TableCell style={{ padding: 0 }}>
          <IconButton color="primary" aria-label="row.cdr" style={{ display: 'flex', float: 'left' }}
            onClick={this.toggleDialogRepuesto.bind(this, row)} title="Detalle de Ficha" >
            <List>{row.cdr}</List>
          </IconButton>  
        </TableCell>
      );
    }
    if (column.name === 'idficha' ) {
      return (
        <TableCell style={{ padding: 0 }}>
          <a href={"/consulta/diagnosticodomicilio?ficha="+row.idficha}>{row.idficha}</a>  
        </TableCell>
      );
    }

    return <VirtualTable.Cell {...props} />;
  };

 
  toggleDialogSeguimiento(documento) {
    if (!this.state.showSeguimientoDialog) {

    } else {
      this.setState({ seguimientoRows: [] });
    }
    this.setState({ showSeguimientoDialog: !this.state.showSeguimientoDialog })
  };

   

  

  toggleDialogRepuesto = (documento) => {
    console.log("valor seleccionado" , documento)

    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    if (documento  != null){
      add();
      let documentoRepuesto = {
        operacion: "repuesto",
        id: documento.id
      }
      this.props.actions.consultarDocumentoPorOtrosCampos(documentoRepuesto).then(response => {
        console.log("respuesta de los repuestos", response); 
        if (response.status == 200) {
          console.log("datos de los repuestos", response.data );
          this.setState({ documento: documento})
          this.setState({ repuestos: response.data})
          this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog })
        } else{
            show("1", "No se encontraron datos a mostrar.", TIPO_MENSAJE.WARNING);
        }
        remove();
      })
    }else{
      this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog })
    }

  };

  exportarExcel = () => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    var fechaEnMiliseg;
    const { totalCount } = this.state;
    var d = new Date();
    fechaEnMiliseg =  ("00" + (d.getMonth() + 1)).slice(-2) + "-" + 
      ("00" + d.getDate()).slice(-2) + "-" + 
      d.getFullYear() + "_" + 
      ("00" + d.getHours()).slice(-2) + "" + 
      ("00" + d.getMinutes()).slice(-2) + "" + 
      ("00" + d.getSeconds()).slice(-2);
    const { documento } = this.props; 
    //if (documento.clieNumero == "" && documento.clieNombre == ""){
      //show("1", "debe filtrar por los datos de un cliente para esta opción", TIPO_MENSAJE.WARNING);
    //}else{
      //console.log("usuario:::", this.props.user);
      add();
      if (totalCount > 0 ){
        this.props.actions.descargarExcel(documento, this.props.user, totalCount).then(response => {
          if (response.tipo === TIPO_MENSAJE.SUCCESS) {
            if (totalCount <= 3000){
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              var json = response.data,
                blob = new Blob([s2ab(atob(json))], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = "pasajes-" + fechaEnMiliseg + ".xlsx";
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);
              show(response.indMostrarMensaje, response.mensaje, response.tipo);
            }else{
              show(MENSAJE.IND_MOSTRAR_MENSAJE, "Se le enviara el excel a su correo electronico en unos minutos.", TIPO_MENSAJE.SUCCESS);
            }
          }else{
            show(response.indMostrarMensaje, response.mensaje, response.tipo);
          }
          remove();
        })
      }else{
        show(MENSAJE.IND_MOSTRAR_MENSAJE, "No existen registros a exportar, consulte la información antes de exportar.", TIPO_MENSAJE.WARNING);
        remove();
      }
  }

  exportarPdf = () => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    var fechaEnMiliseg;
    const { totalCount } = this.state;
    var d = new Date();
    fechaEnMiliseg =  ("00" + (d.getMonth() + 1)).slice(-2) + "-" + 
      ("00" + d.getDate()).slice(-2) + "-" + 
      d.getFullYear() + "_" + 
      ("00" + d.getHours()).slice(-2) + "" + 
      ("00" + d.getMinutes()).slice(-2) + "" + 
      ("00" + d.getSeconds()).slice(-2);
    const { documento } = this.props;
    //if (documento.clieNumero == "" && documento.clieNombre == ""){
      //show("1", "debe filtrar por los datos de un cliente para esta opción", TIPO_MENSAJE.WARNING);
    //}else{
      //console.log("usuario:::", this.props.user);
      add();
      if (totalCount > 0 ){
        documento.operacion = "descargPasaje";
        documento.tiporegistro = "D";

        this.props.actions.consultarFichas(documento, 1, 3000).then(response => {
        //this.props.actions.descargarExcel(documento, this.props.user, totalCount).then(response => {
          if (response.status === 200) {
 
            let dataResponse = response.data;
            let repuestos = response.data;
            /*var groupBy = function(xs, key) {
              return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
              }, {});
            };*/

            const groupBy = ({ Group: array, By: props }) => {
              let getGroupedItems = (item) => {
                  let returnArray = [];
                  let i;
                  for (i = 0; i < props.length; i++) {
                      returnArray.push(item[props[i]]);
                  }
                  return returnArray;
              };
          
              let groups = {};
              let i;
          
              for (i = 0; i < array.length; i++) {
                  const arrayRecord = array[i];
                  const group = JSON.stringify(getGroupedItems(arrayRecord));
                  groups[group] = groups[group] || [];
                  groups[group].push(arrayRecord);
              }
              return Object.keys(groups).map((group) => {
                  return groups[group];
              });
            };
          
            
            console.log("data agrupada", groupBy( {Group: dataResponse, By: ['tecnico','fechaatencion']} ) );  
            let id = (""+documento.id).padStart(10,  "0");

            let dataTecnicos = groupBy( {Group: dataResponse, By: ['tecnico','fechaatencion']} );
            //var doc = new jsPDF('p', 'pt', 'letter', 'mm', 'a6');
            var doc = new jsPDF("landscape", "mm", "a4");
            doc.setProperties({
                  title: 'lista-servicios' + id,
                  subject: 'Lista de servicios de campo',
                  author: 'SERFAC SAC',
                  keywords: 'serfac sac',
                  creator: 'MQ P'
              });

            //var doc = new jsPDF("landscape", "mm", "a4");
            
            //doc.addImage(logo, 30, 20, 188.8, 61.6);
            
              //JsBarcode("#itf", "123456789", {format: "itf"});
            JsBarcode("#itf", id); 
            
           // doc.addImage(logoPdf, 0, 0, 15, 15);
            
            /*var columns = [
              { title: "", dataKey: "id" },
            ];
            var rows = [
              {
                "id": "Servicio Técnico  - SERFAC."
              },
              {
                "id": "Coordinadora: " + documento.tallerres
              },
            ];

            doc.autoTable(columns, rows, {
              startY: 130,
              columnStyles: {
                0: { halign: 'justify' },
              },
              headStyles: {
                fillColor: [255, 255, 255]
              },
              bodyStyles: {
                fillColor: [255, 255, 255],
                fontSize: 10
              },
              margin: { right: 55, left: 55 }
            });*/


            
            doc.setFontSize(20);
          // doc.setFontType('bold');
            var text = "LISTADO DE PROGRAMACIONES";
            var y = 10;
            var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
            doc.text(textOffset, y, text);


            var columns = [];
            
            var rows = [ 
            ];

            
            
          
            rows = [];
            var contado = 0;
            for (var i=0; i < dataTecnicos.length; i++){
                console.log(dataTecnicos[i][0]);
                
                columns.push({ title: dataTecnicos[i][0].tecnico, dataKey: "id" });
                columns.push({ title: dataTecnicos[i][0].fechaatencion, dataKey: "fec" });
                columns.push({ title: "", dataKey: "columnh"});
                columns.push({ title: "", dataKey: "columnh2" });
                console.log("co9lumnas",dataTecnicos);
                for (var x=0; x < dataTecnicos[i].length; x++){
                  rows.push(
                    {
                      "id": "Número de Ficha:", "fec": dataTecnicos[i][x].id, 
                      "columnh": "" , "columnh2": ""
                    },
                  )

                  rows.push(
                    {
                      "id": dataTecnicos[i][x].ttrabajo, "fec": dataTecnicos[i][x].nombre, 
                      "columnh": dataTecnicos[i][x].celular + "/" +dataTecnicos[i][x].telefono , "columnh2": dataTecnicos[i][x].numdoccliente
                    },
                  )
                  rows.push(
                    {
                      "id": dataTecnicos[i][x].codproducto, "fec": "DIRECCIÓN:", 
                      "columnh": dataTecnicos[i][x].distrito , "columnh2": dataTecnicos[i][x].direccion
                    },
                  )

                  rows.push(
                    {
                      "id": dataTecnicos[i][x].marca, "fec": dataTecnicos[i][x].serie, 
                      "columnh": dataTecnicos[i][x].observacion , "columnh2": dataTecnicos[i][x].motivo
                    },
                  )

                  rows.push(
                    {
                      "id": dataTecnicos[i][x].tiposervicio, "fec": dataTecnicos[i][x].numeroservicio, 
                      "columnh": dataTecnicos[i][x].modelo , "columnh2": "Fc.:" +dataTecnicos[i][x].fechacompra
                    },
                  )
                  

                  rows.push(
                    {
                      "id": "", "fec": "", 
                      "columnh": "" , "columnh2": ""
                    },
                  )
                  contado++;
                }

            // #282b95
                doc.autoTable(columns, rows, {
                //  startY: (40 * (i+1)),
                  startX: 10,
                  columnStyles: {
                    0: { columnWidth: 50, halign: 'left' },
                    1: { columnWidth: 70, halign: 'left' },
                    2: { columnWidth: 70, halign: 'left' },
                  },
                  headStyles: {
                    fillColor: [40, 43, 149]
                  },
                  bodyStyles: {
                    fillColor: [255, 255, 255]
                  },
                  margin: { right: 10, left: 10 }

                });
                columns = []
                rows = []
                
            } 
            
            

            


            var d = new Date();
            var mesN = d.getMonth();
            var mes = "";
            switch (mesN) {
              case 0: mes = "Enero"; break;
              case 1: mes = "Febrero"; break;
              case 2: mes = "Marzo"; break;
              case 3: mes = "Abril"; break;
              case 4: mes = "Mayo"; break;
              case 5: mes = "Junio"; break;
              case 6: mes = "Julio"; break;
              case 7: mes = "Agosto"; break;
              case 8: mes = "Setiembre"; break;
              case 9: mes = "Octubre"; break;
              case 10: mes = "Noviembre"; break;
              case 11: mes = "Diciembre"; break;
              default: break;
            }
            //Obtención de Hora       
            var h = d.getHours();
            //Usando formato de 12H
            //Para horas
            var ampm = h >= 12 ? 'PM' : 'AM';
            h = h % 12;
            h = h ? h : 12;
            //Autocompletado de minutos menores a 10 con '0' 
            var m = d.getMinutes();
            m = m < 10 ? '0' + m : m;
            //Autocompletado de segundos menores a 10 con '0' 
            var s = d.getSeconds();
            s = s < 10 ? '0' + s : s;
            //Concatenación de hora
            var hora = h + ':' + m + ':' + s + ' ' + ampm;
            //Especificacion fija de Lugar

            var lugar = 'SERFAC SAC - RUC: 20295833743 - Av. Aviación 2618 San Borja Lima Perú - Telf. 012243261, Cel. 998121831  ';
            const pageCount = doc.internal.getNumberOfPages();
            for (var i = 1; i <= pageCount; i++) {
              doc.setTextColor(187, 177, 175);
              //doc.setFontType('bold');
              doc.setFontSize(9);
              doc.setPage(i);
              
              doc.text(lugar  , 60, 200, null, null, "left");
              doc.text("Correo: atencionalcliente@serfac.com.pe"  , 230, 200, null, null, "left");
              doc.text('Pagina ' + String(i) + ' de ' + String(pageCount), 10, 200, null, null, "left");
            // doc.text(  d.getDate() + " de " + mes + " del " + d.getFullYear() + " " + hora, 550, 780, null, null, "right");

            }

            
            let tipo = 'PDF'
            if (tipo == 'PDF'){
              doc.save("Ficha-de-Trabajo-"+id+".pdf");
              remove();
            }else{
              //remove();
                // enviamos el archivo al correo
              let buffer =  doc.output('datauristring');
              
              let paramsMail = {
                  file: buffer,
                  producto: documento.codproducto,
                  modelo: documento.modelo,
                  serie: documento.serie,
                  marca: documento.marca,
                  email: documento.email,
                  name: "Ficha-de-Trabajo-"+id+".pdf"
              }
              console.log("buffer del PDF", paramsMail);
            // add();
              this.props.actions.enviarMail(paramsMail).then(response => {
                if (response.codigo == 0) { 
                    show("1", "Se envio correctamente el archivo al correo : "+ documento.email, TIPO_MENSAJE.SUCCESS);
                }else{
                    show("1", "Ocurrio un error al enviar el correo : "+ documento.email, TIPO_MENSAJE.WARNING);
                }
                remove();
              });
              
          }
          }else{
            show(response.indMostrarMensaje, response.mensaje, response.tipo);
          }
          remove();
        })
      }else{
        show(MENSAJE.IND_MOSTRAR_MENSAJE, "No existen registros a exportar, consulte la información antes de exportar.", TIPO_MENSAJE.WARNING);
        remove();
      }
  }



  toggleDialogRepuestoRegistro = (currentPage, page) => {
     this.buscarDocumentos(currentPage, page);
     this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog }) 
  }; 

  render() {
    const {
      rows,
      columns,
      tableColumnExtensions,
      columnsSeguimiento,
      tableColumnSeguimientoExtensions,
      headersCsv,
      sorting,
      selection,
      currentPage,
      pageSize,
      pageSizes,
      totalCount,
      seguimientoRows,
      showSeguimientoDialog,
      showRepuestoDialog,
      repuestos,
      documento,
      showGuadar
    } = this.state;

    const {  classes } = this.props;

    return (
      <GridContainer>
       <GridItem xs={12} sm={12} md={12}>
          <IconButton color="primary" aria-label="exportar a excel" style={{ float: 'right' }} onClick={this.exportarExcel} title="Solo se exportan los primeros 3000 resultados a excel" >
            <img src={excelPng} height="38" width="38" />
          </IconButton>
          
        </GridItem>
      <div style={{ display: "none" }}> <img id='itf' /></div>
        <GridItem xs={12} sm={12} md={12}>
           
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ width: "100%" }}>
            <Grid
              rows={rows}
              columns={columns}
              style={{ height: "100%" }}
              getRowId={getRowId}
              className={"ps-child"}
            >

              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <SelectionState
                selection={selection}
                onSelectionChange={this.changeSelection}
              />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />
              <CustomPaging
                totalCount={totalCount}
              />
              {}
              {}
              <VirtualTable
                columnExtensions={tableColumnExtensions}
                cellComponent={this.Cell}
                messages={GRID_TEXT_ES}
              />
              <IntegratedSelection />
              <TableSelection showSelectAll />
              <TableHeaderRow showSortingControls />
              <PagingPanel
                pageSizes={pageSizes}
                messages={GRID_TEXT_ES}
              />
            </Grid>
            <PasajeDialog currentPage={currentPage} pageSize={pageSize} toggleDialogRepuesto={this.toggleDialogRepuesto} toggleDialogRepuestoRegistro={this.toggleDialogRepuestoRegistro} showRepuestoDialog={showRepuestoDialog} classes={classes} repuestos={repuestos} documento={documento}
               />
          </Paper>
        </GridItem>
      </GridContainer>

    );
  }
}

const mapState = (state) => ({
  loading: state.load.loading,
  user: state.session.user
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

export default withRouter(connect(mapState, mapDispatch)(BsqDomPasajeGrid));