import "react-app-polyfill/ie9"
import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import 'raf/polyfill';
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { unregister } from "registerServiceWorker";
import store from "utils/ConfigureStore";
import Amplify from "aws-amplify";
import config from "commons/config/AWSConfig";
import App from "App";
import { Auth } from 'aws-amplify';
import "styles/css/material-dashboard-react.css?v=1.4.0";


// const options = { refreshOnCheckAuth: false, redirectPath: '/Login', driver: 'LOCALSTORAGE', expires: 3600 };
//Amplify.Logger.LOG_LEVEL = 'DEBUG'
Amplify.configure(
  {
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        region: config.apiGateway.REGION,
        name: config.apiGateway.NAME,
        endpoint: config.apiGateway.URL,        
      /*  custom_header: async () => {
          // With Cognito User Pools use this:
          return { user: (await Auth.currentSession()).idToken.jwtToken } 
        },*/
        //region: config.apiGateway.REGION
      }
    ]
  }
});
const hist = createBrowserHistory();
unregister();

ReactDOM.render(
  <Provider store={store} >
    <App hist={hist} />
  </Provider>, document.getElementById('root')
);