import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  SelectionState, IntegratedSelection, IntegratedSorting, SortingState, PagingState, CustomPaging
} from '@devexpress/dx-react-grid';
import {
  Grid, VirtualTable, Table,
  TableHeaderRow, PagingPanel, TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import * as documentoActions from "actions/DocumentoActions";
import * as notificationActions from "actions/NotificationActions";
//import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";

import {
  TIPO_MENSAJE, GRID_TEXT_ES, MENSAJE, MENSAJE_GENERAL
} from 'utils/Constants';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import AnularIcon from '@material-ui/icons/Close';
import List from '@material-ui/icons/List';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "components/CustomButtons/Button";
import Typography from '@material-ui/core/Typography';
//import { s2ab } from 'utils/Functions';
import CsvCreator from 'react-csv-creator';
import SvgIcon from '@material-ui/core/SvgIcon';
import cdrPng from "images/icons/cdr.png";
import xmlPng from "images/icons/xml.png";
import zipPng from "images/icons/zip.png";
import excelPng from "images/icons/excel.png";
import sunatPng from "images/icons/sunat.png";
import logoPdf from "images/logopdfmin.png";
import logoPdfSerfac from "images/logopdfminserfac.png";

import logoIndurama from "images/logoindurama.png";
import logoBarker from "images/logobarker.png";
import { s2ab, getBase64FromFile } from 'utils/Functions';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { blue, red } from '@material-ui/core/colors';

import RepuestoDialog from "components/Dialog/RepuestoDialog";
import logo from "images/logo-rimac-r.png";
import JsBarcode from 'jsbarcode';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import config from "commons/config/AWSConfig";
import tcSerfac from "images/serfac-tc-650.png";
import tcTecnico from "images/tecnico-express-tc-650.png";
const getRowId = row => row.id;

class BsqDocumentoGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
         { name: 'id', title: ' Ficha' },
        { name: 'fechaingreso', title: ' Emisión' },
        { name: 'codproducto', title: 'Producto ' },
        { name: 'modelo', title: 'Modelo' },
        { name: 'marca', title: 'Marca' },
        { name: 'serie', title: 'Serie' },
        { name: 'estadoname', title: 'Estado' },
        { name: 'ubicacion', title: 'ubicacion' },
        { name: 'importe', title: 'Importe' },
        { name: 'archivo', title: 'Acción' },
        { name: 'numeroservicio', title: 'Número O/S' },
        { name: 'nombre', title: 'Cliente' },
        { name: 'tecnico', title: 'Tecnico' },
      ],
      tableColumnExtensions: [
        { columnName: 'id', width: 70 },
        { columnName: 'fechaingreso', width: 100, wordWrapEnabled: true },
        { columnName: 'codproducto', width: 120, wordWrapEnabled: true },
        { columnName: 'modelo', width: 120, wordWrapEnabled: true },
        { columnName: 'marca', width: 100 },
        { columnName: 'serie', width: 100 },
        { columnName: 'estadoname', width: 170 },
        { columnName: 'ubicacion', width: 100 },
        { columnName: 'importe', width: 80 },
        { columnName: 'nombre', width: 100, wordWrapEnabled: true },
        { columnName: 'tecnico', width: 180, wordWrapEnabled: true },
        { columnName: 'numeroservicio', width: 130, wordWrapEnabled: true },
        { columnName: 'archivo', width: 200 },
      ],
      columnsSeguimiento: [
        { name: 'fecha_seguimiento', title: ' Fecha' },
        { name: 'cdr_code', title: 'Código ' },
        //{ name: 'estado_seguimiento', title: 'Estado' },
        { name: 'cdr_nota', title: 'Nota' },
        { name: 'cdr_observacion', title: 'Observación' }
      ],
      tableColumnSeguimientoExtensions: [
        { columnName: 'fecha_seguimiento', width: 120, wordWrapEnabled: true },
        { columnName: 'cdr_code', width: 80, wordWrapEnabled: true },
        //{ columnName: 'estado_seguimiento', width: 100, wordWrapEnabled: true },
        { columnName: 'cdr_nota', width: 250, wordWrapEnabled: true },
        { columnName: 'cdr_observacion', width: 250, wordWrapEnabled: true }
      ],
      headersCsv: [{
        id: 'id',
        display: 'id'
      }, {
        id: 'fecha_seguimiento',
        display: 'Fecha'
      }, {
        id: 'cdr_code',
        display: 'Codigo'
      },/* {
        id: 'estado_seguimiento',
        display: 'Estado'
      },*/ {
        id: 'cdr_nota',
        display: 'Nota'
      }, {
        id: 'cdr_observacion',
        display: 'Observacion'
      }],
      rows: [],
      //sorting: [{ columnName: 'docu_fecha', direction: 'asc' }],
      sorting: [],
      currentPage: 0,
      totalCount: 0,
      pageSize: 10,
      pageSizes: [10, 20, 50, 100],
      showSeguimientoDialog: false,
      seguimientoRows: [],
      selection: [],
      showUsuarioDialog: false,
      repuestos: [],
      documento:{}
    };

    this.changeSorting = sorting => this.setState({ sorting });
    this.changeSelection = selection => {
      const { show } = this.props.notificationActions;
      // console.log(selection);
      //console.log(selection.length);
      if (selection.length > 250){
        show("1", "La cantidad de archivos a descargar debe ser menor a 250", TIPO_MENSAJE.WARNING);
        //console.log("error la cantidad de archivos a descargar debe ser menor a 250");
      }
      this.setState({ selection: selection });
    }
    //this.changeCurrentPage = currentPage => this.setState({ currentPage });
    //this.changePageSize = pageSize => this.setState({ pageSize });

  }

  changeCurrentPage = (currentPage) => {
    this.setState({ currentPage: currentPage })
    this.buscarDocumentos(currentPage);
  }

  changePageSize = (pageSize) => {
    const { totalCount, currentPage: stateCurrentPage } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(stateCurrentPage, totalPages - 1);

    this.setState({
      pageSize: pageSize,
      currentPage: currentPage
    });
    this.buscarDocumentos(currentPage, pageSize);
  }

  componentDidMount() {
    this.buscarDocumentos();
    this.state.selection = [];
    this.props.onRef(this);
    //this.buscarDocumentos();
  }

  limpiarForm = () => {
    //this.props.reset();
    var documento = {
      docuNumero: '',
      tipoDocumento: '',
      clieNumero: '',  //es ruc
      docuFechaIni: '',
      docuFechaFin: '',
      estado: '',
      clieNombre: '', //razon Social
      cdr: ''
    };
    
    this.setState({ rows: [] });
    this.setState({ documento: documento });
  }

  buscarDocumentos(page, size) {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    const { currentPage, pageSize } = this.state;
    add();
    page = page ? page : currentPage;
    size = size ? size : pageSize;

    if (page === -1) {
      page = 0;
      this.limpiarForm();
    }
    if (page === currentPage) { page = 0; }
    //validamos filtros
    let {documento} = this.props;
    if (localStorage.getItem("tkn") == "serfac-ilumi"){ 
      documento.area = 15; // siempre para ilumi 15
    }

    if (localStorage.getItem("tkn") == "serfac-barker"){ 
      documento.area = 20; // siempre para barker 20
    }

    if (localStorage.getItem("tkn") == "serfac-indurama"){ 
      documento.area = documento.area ? documento.area : "18,19"; // 
    }

    this.props.actions.consultarFichas(documento, page + 1, size).then(response => {
      console.log("response de dataaaaa", response);
      if (response.status === 200) {
        this.setState({ rows: response.data, totalCount: response.totalCount })
      } else {
        if (response.data != null && response.data == 'dato invalido'){
          show(response.indMostrarMensaje, "Ingrese información valida para la busqueda", TIPO_MENSAJE.WARNING);
        }else{
          show(response.indMostrarMensaje, response.mensaje, response.tipo);
        }
      }
      remove();
    })
  }

  
  descargarArchivo(documento, tipo) {
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    add();
    let documentoRepuesto = {
        operacion: "repuesto",
        id: documento.id
    }
    this.props.actions.consultarDocumentoPorOtrosCampos(documentoRepuesto).then(response => {
      console.log("râ555555555", response); 
        if (response.status == 200) {
          console.log("datos de los repuestos", response.data );
  
          let repuestos = response.data;


          console.log("documento", documento);
          let id = (""+documento.id).padStart(10,  "0");

          var doc = new jsPDF('p', 'pt', 'letter', 'mm', 'a6');

          doc.setProperties({
                title: 'ficha-trabajo-' + id,
                subject: 'ficha de trabajo',
                author: 'SERFAC SAC',
                keywords: 'generated, javascript, web 2.0, ajax',
                creator: 'MQ MQ'
            });

          //var doc = new jsPDF("landscape", "mm", "a4");
           
          //doc.addImage(logo, 30, 20, 188.8, 61.6);
           
            //JsBarcode("#itf", "123456789", {format: "itf"});
          JsBarcode("#itf", id);
          const img = document.querySelector('img#itf');
           doc.addImage(img, 380, 20, 188.8, 61.6);

           
           if (documento.area == 18 || documento.area == 19  ) { // cambio 18/11/2024
            doc.addImage(logoIndurama, 230, 20, 120.8, 61.6);
            doc.addImage(logoPdf, 60, 20, 120.8, 61.6);
           }else if (documento.area == 20  ) {  
            doc.addImage(logoBarker, 230, 20, 120.8, 61.6);
            doc.addImage(logoPdf, 60, 20, 120.8, 61.6);
           }else{
             if (documento.marcacodigo == 17){
                doc.addImage(logoPdf, 60, 20, 120.8, 61.6);
             }else{
               doc.addImage(logoPdfSerfac, 60, 20, 120.8, 61.6);
             }
            
           }
           

           
           
           var columns = [
            { title: "", dataKey: "id" },
            { title: "", dataKey: "texto" },
          ];
          var rows = [
            {
              "id": "Servicio - TÉCNICO EXPRESS", 
              "texto": "Coordinadora: " + documento.tallerres
            } 
          ];

          doc.autoTable(columns, rows, {
            startY: 130,
            columnStyles: {
              0: { halign: 'justify' },
            },
            headStyles: {
              fillColor: [255, 255, 255]
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              fontSize: 7
            },
            margin: { right: 55, left: 55 }
          });
          doc.setFontSize(20);
         // doc.setFontType('bold');
          var text = "FICHA DE TRABAJO";
          var y = 120;
          var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
          doc.text(textOffset, y, text);


           

          columns = [
            { title: "DATOS DEL CLIENTE", dataKey: "id" },
            { title: "", dataKey: "name" },
            { title: "", dataKey: "id2" },
            { title: "", dataKey: "name2" },
          ];
       
          rows = [
            { "id": "Nombre: ", "name": documento.nombre, "id2": "Documento: ", "name2": documento.numdoccliente}, 
            { "id": "Email: ", "name": documento.email, "id2": "Telefono: ", "name2": documento.telefono}, 
            { "id": "Dirección: ", "name":  documento.direccion, "id2": "Distrito: ", "name2":  documento.distrito}, 
            { "id": "Referencia: ", "name":  documento.referencia, "id2": "", "name2":  ""}
          ];
          // #282b95
          doc.autoTable(columns, rows, {
            startY: doc.lastAutoTable.finalY + 5,
            columnStyles: {
              0: { columnWidth: 100, halign: 'right' },
              1: { halign: 'left', columnWidth: 170 },
              2: { columnWidth: 100, halign: 'right' },
              3: { columnWidth: 150, halign: 'left' },
            },
            headStyles: {
              fillColor: [40, 43, 149],
              fontSize: 8
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              fontSize: 8,
              cellWidth: 'wrap'
            },
            margin: { right: 55, left: 55 }

          });

          columns = [
            { title: "DATOS DEL PRODUCTO", dataKey: "id" },
            { title: "", dataKey: "name" },
            { title: "", dataKey: "id2" },
            { title: "", dataKey: "name2" },
          ];

          rows = [
            { "id": "Fecha de Ingreso: ", "name": documento.fechaingreso, "id2": "Artefacto: ", "name2": documento.codproducto }, 
            { "id": "Modelo: ", "name": documento.modelo, "id2": "Serie: ", "name2": documento.serie  }, 
            { "id": "Marca: ", "name": documento.marca, "id2": "Tipo de Servicio: ", "name2": documento.tiposervicio}, 
            { "id": "Orden de Servicio: ", "name": documento.numeroservicio, "id2": "FACT/BOL/GUIA: ", "name2": documento.comprobante},  
            { "id": "Tienda: ", "name": documento.tiendaname,  "id2": "Motivo: ", "name2": documento.motivo}
          ];
          if ( documento.tiposervicio == "GARANTIA" ){
            rows.push(
                { "id": "Fecha de Venta: ", "name": documento.fechaventa}
            );
          }

          doc.autoTable(columns, rows, {
            startY: doc.lastAutoTable.finalY + 5,
            columnStyles: { 
              0: { columnWidth: 100, halign: 'right' },
              1: { halign: 'left', columnWidth: 150 },
              2: { columnWidth: 100, halign: 'right' },
              3: { columnWidth: 150, halign: 'left' },
            }, 
            headStyles: {
              fillColor: [40, 43, 149],
              fontSize: 7
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              fontSize: 8,
              cellWidth: 'wrap'
            },
            margin: { right: 55, left: 55 }

          });

          columns = [
            { title: "DATOS DEL SERVICIO", dataKey: "id" },
            { title: "", dataKey: "name" },
          ];

          rows = [
            { "id": "Observación: ", "name": documento.observacion}, 
            { "id": "Recomendaciones del Tecnico: ", "name": documento.recomendacion  } ,
            { "id": "Fecha de Atención: ", "name": documento.fechaatencion  } 
          ];

          doc.autoTable(columns, rows, {
            startY: doc.lastAutoTable.finalY + 5,
            columnStyles: {
              0: { columnWidth: 200, halign: 'right' },
              1: { halign: 'left' },
            },
            headStyles: {
              fillColor: [40, 43, 149],
              fontSize: 7
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              fontSize: 7
            },
            margin: { right: 55, left: 55 }

          });

          columns = [
            { title: "MANO DE OBRA Y/O REPUESTOS", dataKey: "nombre" },
            { title: "cantidad", dataKey: "cantidad" },
            { title: "precio", dataKey: "precio" },
            { title: "total", dataKey: "total" },
          ];
          rows = [];
          for (var i=0; i < repuestos.length; i++){
              let reppp = {
                "nombre": repuestos[i].nombre, 
                "cantidad": repuestos[i].cantidad,
                "precio": repuestos[i].precio,
                "total": repuestos[i].total
              }
              rows.push(reppp);
          } 

          doc.autoTable(columns, rows, {
            startY: doc.lastAutoTable.finalY + 5,
            columnStyles: {
              0: { columnWidth: 200, halign: 'right' },
              1: { halign: 'left' },
            },
            headStyles: {
              fillColor: [40, 43, 149],
              fontSize: 7
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              fontSize: 7
            },
            margin: { right: 55, left: 55 }

          }); 
          columns = [
            { title: "TOTAL DEL SERVICIO" , dataKey: "id" },
            { title: "", dataKey: "name" },
          ];

          rows = [
            { "id": "Importe a Pagar: ", "name": documento.importe  } 
          ];

          doc.autoTable(columns, rows, {
            startY:  doc.lastAutoTable.finalY + 5,
            columnStyles: {
              0: { columnWidth: 200, halign: 'right' },
              1: { halign: 'left' },
            },
            headStyles: {
              fillColor: [40, 43, 149],
              fontSize: 7
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              fontSize: 7
            },
            margin: { right: 55, left: 55 }

          });


          columns = [
            { title: "OBSERVACIONES DEL CLIENTE", dataKey: "id" }
          ];

          rows = [
            { "id": documento.obscliente} 
          ];

          if (doc.lastAutoTable.finalY >= 590){
            doc.addPage();
          }
          
          doc.autoTable(columns, rows, {
            startY: doc.lastAutoTable.finalY >= 590 ? 20 : doc.lastAutoTable.finalY + 5,
            headStyles: {
              fillColor: [40, 43, 149],
              fontSize: 7
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              fontSize: 7
            },
            margin: { right: 55, left: 55 }

          }); 

          columns = [
            { title: "", dataKey: "id" }
          ];
          let firmy = doc.lastAutoTable.finalY;
          rows = [
            { "id": "____________________________" },
            { "id": "Firma del cliente" } ,
            { "id": documento.nombre} ,
            { "id": documento.numdoccliente} 
          ];
          doc.autoTable(columns, rows, {
            startY: doc.lastAutoTable.finalY + 50,
            headStyles: {
              fillColor: [255, 255, 255],
              fontSize: 9
            },
            
            alternateRowStyles: {
              fillColor: [255, 255, 255], 
            },
            tableLineColor: {
              fillColor: [255, 255, 255], 
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              fontSize: 9
            },
            margin: { right: 55, left: 55 }

          }); 

          doc.setTextColor(187, 177, 175);

          doc.setFontSize(9);

            //doc.setFontType('bold');
           

          try{
            var imgSigne = new Image();
            imgSigne.crossOrigin = "Anonymous";
            imgSigne.src = "https://d3jz9lyib2o491.cloudfront.net/IMAGES/"+documento.fechaingreso.substr(0,10).replace(/-/g,"/") + "/"+ documento.id + "/FIRMA/" + documento.id + ".png";
            doc.addImage(imgSigne, 55, firmy , 120.8, 61.6); 

          }catch(err){

          }   
          

          doc.addPage();
          doc.setFontSize(9);
          if (documento.area == 18 || documento.area == 19 || documento.area == 20 || documento.marcacodigo == 17) {
            doc.addImage(tcTecnico , 20, 0);  
          }else{
            doc.addImage(tcSerfac , 20, 0);  
          }
          


          


          var d = new Date();
          var mesN = d.getMonth();
          var mes = "";
          switch (mesN) {
            case 0: mes = "Enero"; break;
            case 1: mes = "Febrero"; break;
            case 2: mes = "Marzo"; break;
            case 3: mes = "Abril"; break;
            case 4: mes = "Mayo"; break;
            case 5: mes = "Junio"; break;
            case 6: mes = "Julio"; break;
            case 7: mes = "Agosto"; break;
            case 8: mes = "Setiembre"; break;
            case 9: mes = "Octubre"; break;
            case 10: mes = "Noviembre"; break;
            case 11: mes = "Diciembre"; break;
            default: break;
          }
          //Obtención de Hora       
          var h = d.getHours();
          //Usando formato de 12H
          //Para horas
          var ampm = h >= 12 ? 'PM' : 'AM';
          h = h % 12;
          h = h ? h : 12;
          //Autocompletado de minutos menores a 10 con '0' 
          var m = d.getMinutes();
          m = m < 10 ? '0' + m : m;
          //Autocompletado de segundos menores a 10 con '0' 
          var s = d.getSeconds();
          s = s < 10 ? '0' + s : s;
          //Concatenación de hora
          var hora = h + ':' + m + ':' + s + ' ' + ampm;
          //Especificacion fija de Lugar

          var lugar = 'Técnico Express - RUC: 20611124237 - Av. Aviación 2618 San Borja Lima Perú - Telf. 012243261, Cel. 933548755   ';
          const pageCount = doc.internal.getNumberOfPages();
          for (var i = 1; i <= pageCount; i++) {
            doc.setTextColor(187, 177, 175);
            //doc.setFontType('bold'); 
            doc.setFontSize(9);
            doc.setPage(i);
            
            doc.text(lugar  , 60, 760, null, null, "left");
            doc.text("Correo: info@tecnicoexpress.com"  , 230, 770, null, null, "left");
            doc.text('Pagina ' + String(i) + ' de ' + String(pageCount), 60, 780, null, null, "left");
           // doc.text(  d.getDate() + " de " + mes + " del " + d.getFullYear() + " " + hora, 550, 780, null, null, "right");

          } 
           

          if (tipo == 'PDF'){
             doc.save("Ficha-de-Trabajo-"+id+".pdf");
             remove();
          }else{
             //remove();
              // enviamos el archivo al correo
             let buffer =  doc.output('datauristring');
            
             let paramsMail = {
                file: buffer,
                producto: documento.codproducto,
                modelo: documento.modelo,
                serie: documento.serie,
                marca: documento.marca,
                email: documento.email,
                data: documento,
                name: "Ficha-de-Trabajo-"+id+".pdf"
             }
            console.log("buffer del PDF", paramsMail);
           // add();
            this.props.actions.enviarMail(paramsMail).then(response => {
              if (response.codigo == 0) { 
                   show("1", "Se envio correctamente el archivo al correo : "+ documento.email, TIPO_MENSAJE.SUCCESS);
              }else{
                  show("1", "Ocurrio un error al enviar el correo : "+ documento.email, TIPO_MENSAJE.WARNING);
              }
              remove();
            });
             
         }
      } else{
            show("1", "No se encontraron datos a mostrar.", TIPO_MENSAJE.WARNING);
            remove();
        }
        remove();
      })
  }


  descargarArchivo_Old(documento, tipo) {
    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    add();
    let documentoRepuesto = {
        operacion: "repuesto",
        id: documento.id
    }
    this.props.actions.consultarDocumentoPorOtrosCampos(documentoRepuesto).then(response => {
        console.log("raaaa444", response); 
        if (response.status == 200) {
          console.log("datos de los repuestos", response.data );
  
          let repuestos = response.data;


          console.log("documento", documento);
          let id = (""+documento.id).padStart(10,  "0");

          var doc = new jsPDF('p', 'pt', 'letter', 'mm', 'a6');

          doc.setProperties({
                title: 'ficha-trabajo-' + id,
                subject: 'ficha de trabajo',
                author: 'SERFAC SAC',
                keywords: 'generated, javascript, web 2.0, ajax',
                creator: 'MQ MQ'
            });

          //var doc = new jsPDF("landscape", "mm", "a4");
           
          //doc.addImage(logo, 30, 20, 188.8, 61.6);
           
            //JsBarcode("#itf", "123456789", {format: "itf"});
          JsBarcode("#itf", id);
          const img = document.querySelector('img#itf');
           doc.addImage(img, 380, 20, 188.8, 61.6);
           
           doc.addImage(logoPdf, 60, 20, 120.8, 61.6);
           if (documento.area == 18 || documento.area == 19) {
            doc.addImage(logoIndurama, 230, 20, 120.8, 61.6);
           }

           if (documento.area == 20) {
            doc.addImage(logoBarker, 230, 20, 120.8, 61.6);
           }

           


           var columns = [
            { title: "", dataKey: "id" },
          ];
          var rows = [
            {
              "id": "Servicio Técnico  - SERFAC."
            },
            {
              "id": "Coordinadora: " + documento.tallerres
            },
          ];

          doc.autoTable(columns, rows, {
            startY: 130,
            columnStyles: {
              0: { halign: 'justify' },
            },
            headStyles: {
              fillColor: [255, 255, 255]
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              fontSize: 10
            },
            margin: { right: 55, left: 55 }
          });
          doc.setFontSize(20);
         // doc.setFontType('bold');
          var text = "FICHA DE TRABAJO";
          var y = 120;
          var textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
          doc.text(textOffset, y, text);


           

          columns = [
            { title: "DATOS DEL CLIENTE", dataKey: "id" },
            { title: "", dataKey: "name" },
          ];
       
          rows = [
            { "id": "Nombre: ", "name": documento.nombre},
            { "id": "Documento: ", "name": documento.numdoccliente},
            { "id": "Email: ", "name": documento.email},
            { "id": "Telefono: ", "name": documento.telefono},
            { "id": "Dirección: ", "name":  documento.direccion},
            { "id": "Distrito: ", "name":  documento.distrito}
          ];
          // #282b95
          doc.autoTable(columns, rows, {
            columnStyles: {
              0: { columnWidth: 200, halign: 'right' },
              1: { halign: 'left' },
            },
            headStyles: {
              fillColor: [40, 43, 149]
            },
            bodyStyles: {
              fillColor: [255, 255, 255]
            },
            margin: { right: 55, left: 55 }

          });

          columns = [
            { title: "DATOS DEL PRODUCTO", dataKey: "id" },
            { title: "", dataKey: "name" },
          ];

          rows = [
            { "id": "Fecha de Ingreso: ", "name": documento.fechaingreso  },
            { "id": "Artefacto: ", "name": documento.codproducto  },
            { "id": "Modelo: ", "name": documento.modelo  },
            { "id": "Serie: ", "name": documento.serie },
            { "id": "Marca: ", "name": documento.marca},
            { "id": "Motivo: ", "name": documento.motivo},
            { "id": "Orden de Servicio: ", "name": documento.numeroservicio},
            { "id": "FACT/BOL/GUIA: ", "name": documento.comprobante},
            { "id": "Observación: ", "name": documento.observacion},
            { "id": "Tipo de Servicio: ", "name": documento.tiposervicio},
            { "id": "Tienda: ", "name": documento.tiendaname}
          ];
          if ( documento.tiposervicio == "GARANTIA" ){
            rows.push(
                { "id": "Fecha de Venta: ", "name": documento.fechaventa}
            );
          }

          doc.autoTable(columns, rows, {
            columnStyles: {
              0: { columnWidth: 200, halign: 'right' },
              1: { halign: 'left' },
            },
            headStyles: {
              fillColor: [40, 43, 149]
            },
            bodyStyles: {
              fillColor: [255, 255, 255]
            },
            margin: { right: 55, left: 55 }

          });

          columns = [
            { title: "DATOS DEL SERVICIO", dataKey: "id" },
            { title: "", dataKey: "name" },
          ];

          rows = [
            { "id": "Recomendaciones del Tecnico: ", "name": documento.recomendacion  } 
          ];

          doc.autoTable(columns, rows, {
            columnStyles: {
              0: { columnWidth: 200, halign: 'right' },
              1: { halign: 'left' },
            },
            headStyles: {
              fillColor: [40, 43, 149]
            },
            bodyStyles: {
              fillColor: [255, 255, 255]
            },
            margin: { right: 55, left: 55 }

          });

          columns = [
            { title: "MANO DE OBRA Y/O REPUESTOS", dataKey: "nombre" },
            { title: "cantidad", dataKey: "cantidad" },
            { title: "precio", dataKey: "precio" },
            { title: "total", dataKey: "total" },
          ];
          rows = [];
          for (var i=0; i < repuestos.length; i++){
              let reppp = { 
                "nombre": repuestos[i].nombre, 
                "cantidad": repuestos[i].cantidad,
                "precio": repuestos[i].precio,
                "total": repuestos[i].total
              }
              rows.push(reppp);
          } 

          doc.autoTable(columns, rows, {
            columnStyles: {
              0: { columnWidth: 200, halign: 'left' },
              1: { halign: 'left' },
            },
            headStyles: {
              fillColor: [40, 43, 149]
            },
            bodyStyles: {
              fillColor: [255, 255, 255]
            },
            margin: { right: 55, left: 55 }

          });



          columns = [
            { title: "TOTAL DEL SERVICIO", dataKey: "id" },
            { title: "", dataKey: "name" },
          ];

          rows = [
            { "id": "Importe a Pagar: ", "name": documento.importe  } 
          ];

          doc.autoTable(columns, rows, {
            columnStyles: {
              0: { columnWidth: 200, halign: 'right' },
              1: { halign: 'left' },
            },
            headStyles: {
              fillColor: [40, 43, 149]
            },
            bodyStyles: {
              fillColor: [255, 255, 255]
            },
            margin: { right: 55, left: 55 }

          });


          var d = new Date();
          var mesN = d.getMonth();
          var mes = "";
          switch (mesN) {
            case 0: mes = "Enero"; break;
            case 1: mes = "Febrero"; break;
            case 2: mes = "Marzo"; break;
            case 3: mes = "Abril"; break;
            case 4: mes = "Mayo"; break;
            case 5: mes = "Junio"; break;
            case 6: mes = "Julio"; break;
            case 7: mes = "Agosto"; break;
            case 8: mes = "Setiembre"; break;
            case 9: mes = "Octubre"; break;
            case 10: mes = "Noviembre"; break;
            case 11: mes = "Diciembre"; break;
            default: break;
          }
          //Obtención de Hora       
          var h = d.getHours();
          //Usando formato de 12H
          //Para horas
          var ampm = h >= 12 ? 'PM' : 'AM';
          h = h % 12;
          h = h ? h : 12;
          //Autocompletado de minutos menores a 10 con '0' 
          var m = d.getMinutes();
          m = m < 10 ? '0' + m : m;
          //Autocompletado de segundos menores a 10 con '0' 
          var s = d.getSeconds();
          s = s < 10 ? '0' + s : s;
          //Concatenación de hora
          var hora = h + ':' + m + ':' + s + ' ' + ampm;
          //Especificacion fija de Lugar

          var lugar = 'SERFAC SAC - RUC: 20295833743 - Av. Aviación 2618 San Borja Lima Perú - Telf. 012243261, Cel. 998121831  ';
          const pageCount = doc.internal.getNumberOfPages();
          for (var i = 1; i <= pageCount; i++) {
            doc.setTextColor(187, 177, 175);
            //doc.setFontType('bold');
            doc.setFontSize(9);
            doc.setPage(i);
            
            doc.text(lugar  , 60, 760, null, null, "left");
            doc.text("Correo: atencionalcliente@serfac.com.pe"  , 230, 770, null, null, "left");
            doc.text('Pagina ' + String(i) + ' de ' + String(pageCount), 60, 780, null, null, "left");
           // doc.text(  d.getDate() + " de " + mes + " del " + d.getFullYear() + " " + hora, 550, 780, null, null, "right");

          }  
          try{
            var imgSigne = new Image();
            imgSigne.src = "https://"+config.bucket+".s3.amazonaws.com/IMAGES/"+documento.fechaingreso.substr(0,10).replace(/-/g,"/") + "/"+ documento.id + "/FIRMA/" + documento.id + ".png";
            doc.addImage(imgSigne, 100, 590, 120.8, 61.6);
          }catch(err){

          }
          

          doc.text(100, 650, '____________________________');
          doc.text(110, 660, '           Firma del Cliente');
          doc.text(110, 670, '           '+documento.nombre);
          doc.text(110, 680, '           '+documento.numdoccliente);

          if (tipo == 'PDF'){
             doc.save("Ficha-de-Trabajo-"+id+".pdf");
             remove();
          }else{
             //remove();
              // enviamos el archivo al correo
             let buffer =  doc.output('datauristring');
            
             let paramsMail = {
                file: buffer,
                producto: documento.codproducto,
                modelo: documento.modelo,
                serie: documento.serie,
                marca: documento.marca,
                data: documento,
                email: documento.email,
                name: "Ficha-de-Trabajo-"+id+".pdf"
             }
            console.log("buffer del PDF", paramsMail);
           // add();
            this.props.actions.enviarMail(paramsMail).then(response => {
              if (response.codigo == 0) { 
                   show("1", "Se envio correctamente el archivo al correo : "+ documento.email, TIPO_MENSAJE.SUCCESS);
              }else{
                  show("1", "Ocurrio un error al enviar el correo : "+ documento.email, TIPO_MENSAJE.WARNING);
              }
              remove();
            });
             
         }
      } else{
            show("1", "No se encontraron datos a mostrar.", TIPO_MENSAJE.WARNING);
            remove();
        }
        remove();
      })
  }


  descargarCodeBar(documento, tipo) {
    
          console.log("documento", documento);
          let id = (""+documento.id).padStart(10,  "0");

          var doc = new jsPDF("landscape", "mm", "a4");
           
          //doc.addImage(logo, 30, 20, 188.8, 61.6);
           
            //JsBarcode("#itf", "123456789", {format: "itf"});
          JsBarcode("#itf", id, {format: "code39", fontSize: 150 , width:4,  height:150});
          const img = document.querySelector('img#itf');
           doc.addImage(img, 50, 20 );
 
           var columns = [
            { title: "", dataKey: "id" },
          ]; 
 
          columns = [
            { title: "", dataKey: "id" },
            { title: "", dataKey: "name" },
          ];
          doc.setFontSize(30);
        
          let rows = [
            { "id": "CLIENTE: ", "name": documento.nombre},
            { "id": "MODELO: ", "name": documento.modelo},
            { "id": "SERIE: ", "name": documento.serie},
          ];
          /*doc.autoTable(columns, rows, {
             startY: 100,
            columnStyles: {
              0: { columnWidth: 80, halign: 'right' },
              1: { columnWidth: 150, halign: 'left' },
            },
            headStyles: {
              fillColor: [0, 0, 0]
            },
            bodyStyles: {
              fillColor: [255, 255, 255]
            },
            styles: {overflow: 'linebreak',
                fontSize: 30},
            margin: { right: 55, left: 55 }

          });*/
          doc.text(30, 120, "NOMBRE :  "+ documento.nombre);
          doc.text(30, 135, "MODELO : "+documento.modelo);
          doc.text(30, 150, "SERIE      : "+ documento.serie);
          doc.setFontSize(24);
          var lugar = 'SERFAC SAC - RUC: 20295833743 - Av. Aviación 2618 San Borja Lima Perú   ';
          var lugar2 = ' Telf. 012243261, Cel. 998121831 - Correo: atencionalcliente@serfac.com.pe  ';
 
          var y = 175;
          var textWidth = doc.getStringUnitWidth(lugar) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          var textOffset = (doc.internal.pageSize.width - textWidth) / 2;

          var textWidth2 = doc.getStringUnitWidth(lugar2) * doc.internal.getFontSize() / doc.internal.scaleFactor;
          var textOffset2 = (doc.internal.pageSize.width - textWidth2) / 2;

          

          doc.text(textOffset, y, lugar);
          y = 185;
           doc.text(textOffset2, y, lugar2);
          //doc.save("codigo-de-barras-"+id+".pdf"); 
          doc.autoPrint();
          window.open(doc.output('bloburl'), '_blank');
  }


  Cell = (props) => {
    const { column, row } = props;
    
    if (column.name === 'estadoname' &&  row.estado == 0) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="secondary" avatar={<Avatar>R</Avatar>} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 10) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} icon={<DoneIcon  />} color="primary" avatar={<Avatar>E</Avatar>} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 11) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>R</Avatar>} style={{background: "#e29b51", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 2) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>C</Avatar>} style={{background: "#00ff7e" , color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 3) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>A</Avatar>} style={{background: "#c57bc0", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 9) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} icon={<DoneIcon  />} color="yellow" avatar={<Avatar>D</Avatar>} style={{background: "#3a115f", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 13) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>R</Avatar>} style={{background: "#16886e", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' &&  row.estado == 14) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname} color="yellow" avatar={<Avatar>N</Avatar>} style={{background: "rgb(111 76 56)", color: "white"}} />      
          
        </TableCell>
      );
    }else if (column.name === 'estadoname' ) {
      return (
        <TableCell style={{ padding: 0 }}>

          <Chip label={row.estadoname}   />      
          
        </TableCell>
      );
     }

    if (column.name === 'archivo' ) {
      return (
        <TableCell style={{ padding: 0 }}>
          <IconButton color="primary" aria-label="row.cdr" style={{ display: 'flex', float: 'left' }}
            onClick={this.toggleDialogRepuesto.bind(this, row)} title="Detalle de Ficha" >
            <List>{row.cdr}</List>
          </IconButton>
          <IconButton color="primary" aria-label="row.cdr" style={{ display: 'flex', float: 'left' }} 
          onClick={this.descargarArchivo.bind(this, row, 'PDF')} title="Descargar Ficha" >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z" /></svg>
          </IconButton>
          <IconButton color="primary" aria-label="row.cdr" style={{ display: 'flex', float: 'left' }}
            onClick={this.descargarCodeBar.bind(this, row)} title="Código de Barras" >
            <ViewWeekIcon>{row.cdr}</ViewWeekIcon>
          </IconButton>
          <IconButton color="primary" aria-label="row.cdr" style={{ display: 'flex', float: 'left' }}
            onClick={this.descargarArchivo.bind(this, row, 'MAIL')} title="Enviar Ficha por Correo" > 
            <MailOutlineOutlinedIcon>{row.cdr}</MailOutlineOutlinedIcon>
          </IconButton>

          


        </TableCell>
      );
    } 
    return <VirtualTable.Cell {...props} />;
  };

 
  toggleDialogSeguimiento(documento) {
    if (!this.state.showSeguimientoDialog) {

    } else {
      this.setState({ seguimientoRows: [] });
    }
    this.setState({ showSeguimientoDialog: !this.state.showSeguimientoDialog })
  };

   

  

  toggleDialogRepuesto = (documento) => {
    console.log("valor seleccionado" , documento)

    const { show } = this.props.notificationActions;
    const { add, remove } = this.props.loadActions;
    if (documento  != null){
      add();
      let documentoRepuesto = {
        operacion: "repuesto",
        id: documento.id
      }
      this.props.actions.consultarDocumentoPorOtrosCampos(documentoRepuesto).then(response => {
        console.log("respuesta de los repuestos", response); 
        if (response.status == 200) {
          console.log("datos de los repuestos", response.data );
          this.setState({ documento: documento})
          this.setState({ repuestos: response.data})
          this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog })
        } else{
            show("1", "No se encontraron datos a mostrar.", TIPO_MENSAJE.WARNING);
        }
        remove();
      })
    }else{
      this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog })
    }

  };

  exportarExcel = () => {
    const { add, remove } = this.props.loadActions;
    const { show } = this.props.notificationActions;
    var fechaEnMiliseg;
    const { totalCount } = this.state;
    var d = new Date();
    fechaEnMiliseg =  ("00" + (d.getMonth() + 1)).slice(-2) + "-" + 
      ("00" + d.getDate()).slice(-2) + "-" + 
      d.getFullYear() + "_" + 
      ("00" + d.getHours()).slice(-2) + "" + 
      ("00" + d.getMinutes()).slice(-2) + "" + 
      ("00" + d.getSeconds()).slice(-2);
    const { documento } = this.props;
    //if (documento.clieNumero == "" && documento.clieNombre == ""){
      //show("1", "debe filtrar por los datos de un cliente para esta opción", TIPO_MENSAJE.WARNING);
    //}else{
      //console.log("usuario:::", this.props.user);
      add();
      if (totalCount > 0 ){
        this.props.actions.descargarExcel(documento, this.props.user, totalCount).then(response => {
          if (response.tipo === TIPO_MENSAJE.SUCCESS) {
            if (totalCount <= 3000){
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              var json = response.data,
                blob = new Blob([s2ab(atob(json))], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = "artefactos-" + fechaEnMiliseg + ".xlsx";
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);
              show(response.indMostrarMensaje, response.mensaje, response.tipo);
            }else{
              show(MENSAJE.IND_MOSTRAR_MENSAJE, "Se le enviara el excel a su correo electronico en unos minutos.", TIPO_MENSAJE.SUCCESS);
            }
          }else{
            show(response.indMostrarMensaje, response.mensaje, response.tipo);
          }
          remove();
        })
      }else{
        show(MENSAJE.IND_MOSTRAR_MENSAJE, "No existen registros a exportar, consulte la información antes de exportar.", TIPO_MENSAJE.WARNING);
        remove();
      }
  }


  toggleDialogRepuestoRegistro = (currentPage, page) => {
     this.buscarDocumentos(currentPage, page);
     this.setState({ showRepuestoDialog: !this.state.showRepuestoDialog }) 
  }; 

  render() {
    const {
      rows,
      columns,
      tableColumnExtensions,
      columnsSeguimiento,
      tableColumnSeguimientoExtensions,
      headersCsv,
      sorting,
      selection,
      currentPage,
      pageSize,
      pageSizes,
      totalCount,
      seguimientoRows,
      showSeguimientoDialog,
      showRepuestoDialog,
      repuestos,
      documento
    } = this.state;

    const {  classes } = this.props;

    return (
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
          <IconButton color="primary" aria-label="exportar a excel" style={{ display: 'flex', float: 'right' }} onClick={this.exportarExcel} title="Solo se exportan los primeros 3000 resultados a excel" >
            <img src={excelPng} height="38" width="38"/>
          </IconButton>
        </GridItem>
      <div style={{ display: "none" }}> <img id='itf' /></div>
        <GridItem xs={12} sm={12} md={12}>
           
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ width: "100%" }}>
            <Grid
              rows={rows}
              columns={columns}
              style={{ height: "100%" }}
              getRowId={getRowId}
              className={"ps-child"}
            >

              <SortingState
                sorting={sorting}
                onSortingChange={this.changeSorting}
              />
              <SelectionState
                selection={selection}
                onSelectionChange={this.changeSelection}
              />
              <IntegratedSorting />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={this.changePageSize}
              />
              <CustomPaging
                totalCount={totalCount}
              />
              {}
              {}
              <VirtualTable
                columnExtensions={tableColumnExtensions}
                cellComponent={this.Cell}
                messages={GRID_TEXT_ES}
              />
              <IntegratedSelection />
              <TableSelection showSelectAll />
              <TableHeaderRow showSortingControls />
              <PagingPanel
                pageSizes={pageSizes}
                messages={GRID_TEXT_ES}
              />
            </Grid>
            <RepuestoDialog currentPage={currentPage} pageSize={pageSize} toggleDialogRepuesto={this.toggleDialogRepuesto} toggleDialogRepuestoRegistro={this.toggleDialogRepuestoRegistro} showRepuestoDialog={showRepuestoDialog} classes={classes} repuestos={repuestos} documento={documento}
               />
          </Paper>
        </GridItem>
      </GridContainer>

    );
  }
}

const mapState = (state) => ({
  loading: state.load.loading,
  user: state.session.user
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(documentoActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch),
    notificationActions: bindActionCreators(notificationActions, dispatch)
  };
};

export default withRouter(connect(mapState, mapDispatch)(BsqDocumentoGrid));