import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import PersonIcon from "@material-ui/icons/Person";
// core components
import Button from "components/CustomButtons/Button";

import headerLinksStyle from "styles/jss/app/components/headerLinksStyle";
import * as sessionActions from "actions/SessionActions";
import * as loadActions from "actions/LoadActions";

class HeaderLinks extends React.Component {
  state = {
    open: false
  };
  constructor(props, context) {
    super(props, context);
    this.logoutUser = this.logoutUser.bind(this);
  }
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  logoutUser() {
    const { logout } = this.props.actions;
    const { add, remove } = this.props.loadActions;
    //console.log("cerrar sesión en el sistema");
   // localStorage.clear();
    add();
    logout(this.props.history).then(response => {
      remove();
       window.location.href = "/login";
    }).catch(err => {
      remove();
    });
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { classes, user } = this.props;
    const { open } = this.state;
    let name = "";
    let family_name = "";

    if (localStorage.getItem("tkn") == "serfac-tecnicos"){
      name  = "Tecnico"
      family_name = "";
      if (localStorage.getItem("perfil") == "T"){
        let objUser = JSON.parse(localStorage.getItem("userTecnico"));
        name = objUser.nombres;
        family_name = objUser.apellidos;
      }
    }else if (localStorage.getItem("tkn") == "serfac-coordinadora"){
      let objUser = JSON.parse(localStorage.getItem("userCoordinadora"));
      name = objUser.name;
      family_name =  objUser.apellidos;

      //name  = "Coordinadora"
      //family_name = "";
    }else if (localStorage.getItem("tkn") == "serfac-ilumi"){
      let objUser = JSON.parse(localStorage.getItem("userIlumi"));
      name = objUser.name;
      family_name =  objUser.apellidos;

      //name  = "Coordinadora"
      //family_name = "";
    }else if (localStorage.getItem("tkn") == "serfac-barker"){
      let objUser = JSON.parse(localStorage.getItem("userBarker"));
      name = objUser.name;
      family_name =  objUser.apellidos;

      //name  = "Coordinadora"
      //family_name = "";
    }else if (localStorage.getItem("tkn") == "serfac-indurama"){
      let objUser = JSON.parse(localStorage.getItem("userIndurama"));
      name = objUser.name;
      family_name =  objUser.apellidos;

      //name  = "Coordinadora"
      //family_name = "";
    }else{
      name  = "Administrador"
      family_name = "";
    }
      
    //console.log(user);
    return (
      <div>
        <div className={classes.manager}>
          <Hidden smDown>
            <span className={classes.userText}>{name} {family_name}</span>
          </Hidden>
          <Hidden mdUp implementation="css">
            <Button
              buttonRef={node => {
                this.anchorEl = node;
              }}
              color="white"
              aria-owns={open ? "menu-list-grow" : null}
              aria-haspopup="true"
              simple={true}
              onClick={this.handleToggle}
              className={classes.buttonLink}
            >
              <PersonIcon className={classes.icons} />
              <p onClick={this.handleClick} className={classes.linkText}>
                {user.name} {user.family_name}
              </p>
            </Button>
          </Hidden>
          <Hidden smDown>
            <Button
              buttonRef={node => {
                this.anchorEl = node;
              }}
              color="primary"
              justIcon={true}
              aria-owns={open ? "menu-list-grow" : null}
              aria-haspopup="true"
              onClick={this.handleToggle}
              className={classes.buttonLink}
              round
            >
              <PersonIcon className={classes.icons} />
            </Button>
          </Hidden>
          
          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !open }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.logoutUser}
                        className={classes.dropdownItem}
                      >
                        Cerrar Sesión
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  user: state.session.user,
  authenticated: state.session.authenticated
});

const mapDispatch = (dispatch) => {
  return {
    actions: bindActionCreators(sessionActions, dispatch),
    loadActions: bindActionCreators(loadActions, dispatch)
  };
};

HeaderLinks = withRouter(connect(mapState, mapDispatch)(HeaderLinks));

export default withStyles(headerLinksStyle)(HeaderLinks);
